import React, { useCallback, useState } from 'react';
import { DialogContent, DialogActions, Button, DialogTitle, Box, InputLabel,
  TextField, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DateTimePicker } from '@material-ui/pickers';
import { Link } from '@material-ui/core';

import { SelectChip, SelectAsync, CoordinatesInput, PhotoInput, AutocompleteWrapper, AlertWrapper, DialogButton } from 'src/components';
import { FloraRecordInputs, PhenologiesAndPhytosanitaryStatusesInputs, PointQuadratRecordInputs }
  from 'src/scenes/Campaign/scenes/Records/components/FloraRecordInputs';
import checkers from 'src/utils/checkers';
import { isEmpty } from 'src/utils/util';
import { useFetchStatus } from 'src/hooks';


const emptyOption = { label: '', value: '' };

const FloraRecordCreateDialog = props => {
  const { actions, options, getOptions, preselectedData, ctLang } = props;
  const { recordsTypesOptions, coverageOptions, phenologiesStatusesOptions, phytosanitaryStatusesOptions, stratumsOptions,
    transectPointOptions = [] } = options;
  const { getPointsOptions, getSpeciesOptions } = getOptions;

  const [ record, setRecord ] = useState({
    number: 1,
    observation: '',
    type: recordsTypesOptions[0].value,
    coverage: coverageOptions.length > 0 ? coverageOptions[0].value : '',
    phenologiesStatuses: checkers.isFloraPointQuadratRecord(recordsTypesOptions[0].value) ? [ phenologiesStatusesOptions[0].value ] : [],
    phytosanitaryStatuses:
      checkers.isFloraPointQuadratRecord(recordsTypesOptions[0].value) ? [ phytosanitaryStatusesOptions[0].value ] : [],
    individuals: '',
    stratumsIds: stratumsOptions.length > 0 ? [ stratumsOptions[0].value ] : [],
    touches: '',
    transectPoint: transectPointOptions.length > 0 ? transectPointOptions[0].value : '',
    detectionDate: new Date(),
    imgFiles: [],
    imgFilesData: [],
  });

  const [ coords, setCoords ] = useState({ lat: -33.4679997, lng: -70.7003513, accuracy: 0, altitude: 0 });

  const { fetchStatus, setFetchStatus, errorMessage, hasBadData, hasError, fetchStatusEnum, handleConfirm } = useFetchStatus({
    confirmFn: () => actions.createRecord({
      ...record,
      speciesName: selectedSpecies.value ? undefined : selectedSpecies.label,
      ...(record.imgFilesData && { imgFilesData: JSON.stringify(record.imgFilesData) }),
      speciesHash: selectedSpecies.value,
      pointId: selectedPoint.value,
      latitude: coords.lat,
      longitude: coords.lng,
      altitude: coords.altitude,
      accuracy: coords.accuracy,
    }),
    closeFn: actions.closeDialog,
    validationFn: () => ({ isValid: hasSelectedSpecies && exclusiveConditions() }),
  });

  const handleChange = e => {
    e.persist();
    setRecord(prevRecord => ({ ...prevRecord, [e.target.name]: e.target.value }));
  };

  const handleSelect = obj => setRecord(prevRecord => ({ ...prevRecord, ...obj }));

  // imgFilesData es un array de objetos con datos sobre las imágenes de imgFiles (imgFilesData[i] son los datos de imgFiles[i])
  const handleImagesChange = useCallback(({ imgFiles, imgFilesData }) => setRecord(pr =>
    ({ ...pr, imgFiles, ...(imgFilesData && { imgFilesData }) }),
  ), []);

  const [ selectedOptions, setSelectedOptions ] = useState({
    point: preselectedData?.pointSelected || emptyOption,
    species: emptyOption,
  });

  const setSelectedOption = optionKey =>
    ({ label, value, ...others }) => setSelectedOptions(prevOptionsGroup => ({
      ...prevOptionsGroup,
      [ optionKey ]: { label, value, ...others },
    }));

  const { point: selectedPoint, species: selectedSpecies } = selectedOptions;

  const exclusiveConditions = () => (
    isFloraRecord ? isIndividualsEmptyOrBetween1And10000 && hasSelectedPoint
    : isFloraIndividualRecord ? hasCoords
    : isFloraPointQuadratRecord ? hasSelectedPoint && hasValidTouches && (hasSelectedPhenologies || isOnlyDead) && hasSelectedTransectPoint
    : isFloraPointInterceptRecord ? hasSelectedPoint && hasSelectedTransectPoint
    : true
  );

  const { observation, touches, individuals, detectionDate, transectPoint } = record;

  const isFloraRecord = checkers.isFloraRecord(record.type);
  const isForestRecord = checkers.isForestRecord(record.type);
  const isFloraIndividualRecord = checkers.isFloraIndividualRecord(record.type);
  const isFloraPointQuadratRecord = checkers.isFloraPointQuadratRecord(record.type);
  const isFloraPointInterceptRecord = checkers.isFloraPointInterceptRecord(record.type);

  const isIndividualsEmptyOrBetween1And10000 = individuals === '' || individuals === null || (1 <= individuals && individuals <= 10000);

  const hasSelectedPoint = Boolean(selectedPoint.value);
  const hasSelectedSpecies = Boolean(selectedSpecies.label);
  const isPointOptionDisabled = Boolean(preselectedData?.pointSelected);
  const hasTransectPointsOptions = transectPointOptions.length > 0;
  const hasSelectedTransectPoint = Boolean(transectPoint);

  const isPointRequired = !isFloraIndividualRecord;
  const hasMoreThanOneRecordType = !isFloraIndividualRecord && recordsTypesOptions.length > 1;
  const showPoint = false;

  const hasSelectedPhenologies = record.phenologiesStatuses?.length > 0;
  const isOnlyDead = record.phytosanitaryStatuses?.length === 1 && record.phytosanitaryStatuses[0] === 'mu';
  const hasValidTouches = touches ? (1 <= parseInt(touches, 10) && parseInt(touches, 10) <= 100) : undefined;
  const hasCoords = !isEmpty(coords.lat) && !isEmpty(coords.lng);
  const transectRecordHasTransectPoint = (isFloraPointQuadratRecord || isFloraPointInterceptRecord) && !transectPoint;

  return (
    <>
      <DialogTitle id='form-dialog-title'>
        {`Crear registro ${isFloraIndividualRecord ? 'individual' : '' }`}
      </DialogTitle>
      <DialogContent>
        <Box p={1}>
          {!isFloraIndividualRecord &&
            <AlertWrapper severity="info">
              Estás creando un nuevo registro en la {ctLang.point} <strong>{selectedPoint.label}</strong>
            </AlertWrapper>
          }

          {isPointRequired && showPoint &&
            <>
              <Box my={2}>
                <InputLabel required shrink>{ctLang.Point}</InputLabel>
                <SelectAsync
                  id="select-points"
                  value={selectedOptions.point}
                  onChange={
                    pointSelected => {
                      if (pointSelected) {
                        setSelectedOption('point')(pointSelected);
                      }
                    }
                  }
                  {...(isPointOptionDisabled ? {} : { getOptions: ({ queryText }) => getPointsOptions({ queryText }) })}
                  placeholder={`Elige una ${ctLang.point}`}
                  noOptionsText={`No se encontraron ${ctLang.points}`}
                  error={hasBadData && !hasSelectedPoint}
                  disabled={isPointOptionDisabled}
                />
                {hasBadData && !hasSelectedPoint && <FormHelperText error>Debes seleccionar una {ctLang.point}</FormHelperText>}
              </Box>
            </>
          }

          {(isFloraPointQuadratRecord || isFloraPointInterceptRecord) &&
            <Box my={2}>
              <InputLabel shrink>Punto de la transecta</InputLabel>
              <AutocompleteWrapper
                options={transectPointOptions}
                onChange={(event, selectedTransectPoint) => handleSelect({ transectPoint: selectedTransectPoint.value })}
                value={{ label: transectPoint.toString(), value: transectPoint }}
                getOptionSelected={(option, value) => option.value === value.value}
              />
              {hasBadData && !hasSelectedTransectPoint &&
                <FormHelperText error>
                  Debes seleccionar un punto.
                </FormHelperText>
              }
              {!hasTransectPointsOptions &&
                <FormHelperText>
                  Debes&nbsp;
                  <Box component="span" display="inline-flex" color="nviro.primary.main">
                    <Link onClick={() => actions.openEditPointDialog(selectedPoint.data)}
                      color="inherit" component="button" variant="caption">
                      definir el largo y distancia de los intervalos&nbsp;
                    </Link>
                  </Box>
                  de la transecta para tener opciones de puntos
                </FormHelperText>
              }

            </Box>
          }

          {hasMoreThanOneRecordType &&
            <Box my={2}>
              <InputLabel shrink>Tipo</InputLabel>
              <SelectChip
                value={record.type}
                options={recordsTypesOptions}
                onChange={option => {
                  handleSelect({ type: option.value });
                  setSelectedOption('point')(preselectedData?.pointSelected || emptyOption);
                  setSelectedOption('species')(emptyOption);
                  setFetchStatus(fetchStatusEnum.NOT_STARTED);
                }}
              />
            </Box>
          }

          <Box my={2} display="flex">
            <Box width="100%">
              <InputLabel required shrink>Especie <em>(se mostrarán máximo 10 resultados)</em></InputLabel>
              <SelectAsync
                id="select-species"
                initialOptions={false}
                value={selectedSpecies}
                getOptions={
                  ({ queryText }) =>
                    getSpeciesOptions({
                      queryText,
                      recordType: record.type,
                      ...(hasSelectedPoint ? { pointId: selectedPoint.value } : {}),
                      ...((isFloraPointQuadratRecord || isFloraPointInterceptRecord) ? { transectPoint } : {}),
                    })
                }
                disabled={ transectRecordHasTransectPoint }
                placeholder="Elige una especie"
                error={hasBadData && !hasSelectedSpecies}
                useScientificNameFormat
                useScientificNameFormatInPlaceholder={record.scientificName !== null}
                freeSolo
                onChange={optionSelected => {
                  if (typeof optionSelected === 'string') { // Cuando se selecciona con enter llega un string, magic
                    setSelectedOption('species')({ label: optionSelected, value: undefined });
                  } else if (optionSelected?.inputValue) { // Cuando se agrega un nuevo tipo
                    setSelectedOption('species')({ label: optionSelected.inputValue, value: undefined });
                  } else { // Cuando se selecciona normalmente
                    setSelectedOption('species')({ label: optionSelected.label, value: optionSelected.hash });
                  }
                }}
                filterOptions={(options, params) => {
                  const speciesAlreadyExist = (
                    options.some(o => o.label.toLowerCase() === params.inputValue.toLowerCase() && o.userCreatorInfo === 'ti')
                  );
                  if (params.inputValue !== '' && !speciesAlreadyExist) {
                    options.push({
                      inputValue: params.inputValue,
                      label: `Agregar "${params.inputValue}"`,
                    });
                  }
                  return options;
                }}
                getOptionLabel={option => typeof option === 'string' ? option : option.label ? option.label : '' }
              />
              {hasBadData && !hasSelectedSpecies && <FormHelperText error>Debes seleccionar una especie</FormHelperText>}
              {transectRecordHasTransectPoint &&
                <FormHelperText>
                  Debes tener seleccionado un punto de la transecta para poder buscar especies
                </FormHelperText>
              }
            </Box>
          </Box>

          {isFloraRecord &&
            <FloraRecordInputs
              record={record}
              handlers={{ handleChange, handleSelect }}
              validators={{ isIndividualsEmptyOrBetween1And10000, hasBadData }}
              options={{ coverageOptions }}
            />
          }

          {isFloraIndividualRecord &&
            <Box my={2}>
              <InputLabel shrink>Coordenadas</InputLabel>
              <CoordinatesInput required value={coords} onChange={setCoords} />
              {hasBadData && !hasCoords && <FormHelperText error>Debes ingresar latitud y longitud</FormHelperText>}
            </Box>
          }

          {isFloraPointQuadratRecord &&
            <PointQuadratRecordInputs
              record={record}
              handlers={{ handleChange, handleSelect }}
              validators={{ hasValidTouches, hasBadData }}
              options={{ stratumsOptions }}
            />
          }

          {(isFloraRecord || isFloraIndividualRecord || isFloraPointQuadratRecord || isFloraPointInterceptRecord) &&
            <PhenologiesAndPhytosanitaryStatusesInputs
              record={record}
              handlers={{ handleSelect }}
              options={{ phenologiesStatusesOptions, phytosanitaryStatusesOptions }}
              validators={{ hasSelectedPhenologies, isOnlyDead }}
            />
          }

          {!isForestRecord &&
            <Box my={2}>
              <InputLabel shrink>Fecha de detección</InputLabel>
              <Box display="flex" alignItems="center">
                <Box display="flex" flexDirection="column">
                  <DateTimePicker
                    value={detectionDate}
                    ampm={false}
                    TextFieldComponent={props => <TextField {...props} variant="outlined" size="small" />}
                    onChange={detectionDate => handleSelect({ detectionDate: new Date(detectionDate) })}
                    format="DD-MM-YYYY HH:mm"
                  />
                </Box>
              </Box>
            </Box>
          }

          {!isForestRecord &&
            <>
              <Box my={2}>
                <InputLabel shrink>Observaciones</InputLabel>
                <TextField name="observation" value={observation} onChange={handleChange} minRows="2"
                  maxRows="5" multiline fullWidth autoComplete="off" variant="outlined" size="small" />
              </Box>
              <Box my={1} mb={3}>
                <PhotoInput
                  onChange={handleImagesChange}
                  imgFiles={record.imgFiles || []}
                  imgFilesData={record.imgFilesData || []}
                  inputLabelText={'Evidencia (.jpg, .jpeg)'}
                  onlyJpg
                />
              </Box>
            </>
          }

          {isForestRecord &&
            <AlertWrapper severity="info">
              Para <strong>agregar agrupaciones de individuos forestales</strong> debes ir a las acciones de este
              registro ubicadas en la lista de registros
            </AlertWrapper>
          }

          {(hasError || hasBadData) && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Crear</DialogButton>
      </DialogActions>
    </>
  );
};

FloraRecordCreateDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  ctLang: PropTypes.object,
  options: PropTypes.object,
  getOptions: PropTypes.object,
  recordType: PropTypes.string,
  preselectedData: PropTypes.object,
};


export { FloraRecordCreateDialog };