import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ProfileContainer, AccountRemoveContainer } from 'src/scenes/UserSettings/scenes';


const routes = [
  {
    path: 'profile',
    Component: ProfileContainer,
  },
  {
    path: 'data-remove',
    Component: AccountRemoveContainer,
  },
  {
    path: 'subscription',
    Component: () => <>Hola suscripción</>,
  },
  {
    path: 'payment-history',
    Component: () => <>Hola historial de pagos</>,
  },
];


const UserSettingsRoutes = props => {
  const match = useRouteMatch();
  const { path: basePath, url: routeUrl } = match;
  const redirectToUserSettingsMainPage = () => <Redirect to={`${routeUrl}/profile`} />;

  return (
    <Switch>
      {routes.map(({ path, Component, hasNestedRoutes }, index) =>
        <Route
          key={index}
          exact={!hasNestedRoutes}
          path={`${basePath}/${path}`}
          render={rProps => <Component {...rProps} {...props} />}
        />)
      }
      <Route path={basePath} component={redirectToUserSettingsMainPage} />
    </Switch>
  );
};

UserSettingsRoutes.propTypes = {
  company: PropTypes.object.isRequired,
};


export { UserSettingsRoutes };