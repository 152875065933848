import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { UserSettingsDrawer } from 'src/components';
import { UserSettingsRoutes } from 'src/scenes/UserSettings/UserSettingsRoutes';
import { WebContext } from 'src/scenes/web-context';


const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
}));

const UserSettingsContainer = () => {
  const [ name ] = useState('');
  const classes = useStyles();

  const context = useContext(WebContext);

  const { selectedCompany } = context;

  useEffect(() => {
    document.title = name ? `${name} - Nviro Capture` : 'Nviro Capture';
  }, [ name ]);

  return (
    <>
      <UserSettingsDrawer />
      <Box className={classes.mainContainer}>
        <UserSettingsRoutes company={selectedCompany} />
      </Box>
    </>
  );
};


export { UserSettingsContainer };