import config from 'src/config/local';
import MomentUtils from '@date-io/moment';
import piexif from 'piexifjs';
import kurkleColor from '@kurkle/color';


const { moment } = new MomentUtils();

const prependZero = n => (n < 10) ? `0${n}` : String(n);

const formatDate = dateString => {
  const date = new Date(dateString);
  const day = prependZero(date.getDate());
  const month = prependZero(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const inverseFormatDate = dateString => {
  const date = new Date(dateString);
  const day = prependZero(date.getDate());
  const month = prependZero(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${year}/${month}/${day}`;
};

const formatDateExtension = dateString => {
  const date = new Date(dateString);
  const hour = prependZero(date.getHours());
  const minutes = prependZero(date.getMinutes());
  const seconds = prependZero(date.getSeconds());
  return `${hour}:${minutes}:${seconds}`;
};

const humanReadableFormatDateWithHour = dateString => {
  const date = new Date(dateString);
  const formatedDate = formatDate(date);
  const formatedHour = formatDateExtension(date);
  return `Última modificación el ${formatedDate} a las ${formatedHour}`;
};

const humanReadableFormatDate = dateString => {
  const date = new Date(dateString);
  const formatedDate = formatDate(date);
  return `Última modificación el ${formatedDate}`;
};

const humanReadableHrMin = seconds => {
  const hours = Math.floor(seconds / 3600);
  const remainderMinutes = Math.round((seconds - hours * 3600) / 60);
  if (seconds === 3600) {
    return '1 hora';
  } else if (seconds % 3600 === 0) {
    return `${hours} horas`;
  } else if (seconds > 3600) {
    return `${hours} hora${hours > 1 ? 's' : ''} y ${remainderMinutes} minuto${remainderMinutes > 1 ? 's' : ''}`;
  } else if (seconds === 1) {
    return '1 segundo';
  } else if (seconds < 60) {
    return `${seconds} segundos`;
  } else if (seconds === 60) {
    return '1 minuto';
  } else {
    return `${remainderMinutes} minutos`;
  }
};

const humanReadableMinSec = seconds => {
  if (seconds > 60) {
    const minutes = Math.floor(seconds / 60);
    const remainderSeconds = seconds - minutes * 60;
    return `${minutes} minuto${minutes > 1 ? 's' : ''} y ${remainderSeconds} segundo${remainderSeconds > 1 ? 's' : ''}`;
  } else if (seconds === 1) {
    return '1 segundo';
  } else if (seconds === 60) {
    return '1 minuto';
  } else {
    return `${seconds} segundos`;
  }
};

const dayNames = [ 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo' ];
const getDayName = dateString => dayNames[ new Date(dateString).getDay() ];

const monthNames = [ 'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio',
  'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre' ];
const getMonthName = dateString => monthNames[ new Date(dateString).getMonth() ];

const humanReadableDay = dateString => {
  const date = new Date(dateString);
  const dayName = getDayName(dateString);
  const monthName = getMonthName(dateString);
  const day = prependZero(date.getDate());
  const year = date.getFullYear();
  return `${dayName}, ${day} de ${monthName} de ${year}`;
};

const groupByObjKey = (key, array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const addOrReplaceOpacityInColor = (hexOrRgb, opacity = 1) => {
  const shortHexColorReg = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const longHexColorReg = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  const rgbColorReg = /rgb\((\d+), ?(\d+), ?(\d+)(?:, ?[\d.]+)?\)/i;

  if (shortHexColorReg.test(hexOrRgb) || longHexColorReg.test(hexOrRgb)) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hexOrRgb = hexOrRgb.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexOrRgb);
    return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity})` : hexOrRgb;
  }
  if (rgbColorReg.test(hexOrRgb)) {
    return hexOrRgb.replace(rgbColorReg, (m, r, g, b) => `rgba(${r}, ${g}, ${b}, ${opacity})`);
  }
};

const objectIsEmpty = object => Object.entries(object).length === 0 && object.constructor === Object;

const arraysAreEqual = (arr1, arr2) => arr1.length === arr2.length && arr1.every((value, idx) => value === arr2[idx]);

//TODO: crear uno que valga para varios niveles
const objectsAreEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  return keys1.length === keys2.length && keys1.all(key => obj1[key] === obj2[key]);
};

const humanReadableArrayOfStrings = (arrayOfStrings, connector = 'y') => arrayOfStrings.join(', ').replace(/(.*),/, `$1 ${connector}`);

const optionsToObject = optionsArr => optionsArr.reduce((obj, curr) => {
  obj[curr.value] = curr.label;
  return obj;
}, {});

const truncateWithEllipsis = (string = '', charNumber = 5) => string.length > charNumber ? `${string.substring(0, charNumber)}...` : string;

// with thanks to https://stackoverflow.com/a/55756548
const tryParseXml = src => {
  /* returns an XMLDocument, or null if `src` is malformed */
  const key = `a${Math.random().toString(32)}`;
  const parser = new DOMParser();
  let doc = null;
  try {
    doc = parser.parseFromString(`${src}<?${key}?>`, `application/xml`);
  // eslint-disable-next-line
  } catch (_) {}

  if (!(doc instanceof XMLDocument)) {
    return null;
  }

  const lastNode = doc.lastChild;
  if (!(lastNode instanceof ProcessingInstruction) || lastNode.target !== key || lastNode.data !== ``) {
    return null;
  }

  doc.removeChild(lastNode);

  const errElemCount = doc.documentElement.getElementsByTagName(`parsererror`).length;
  if (errElemCount !== 0) {
    let errDoc = null;
    try {
      errDoc = parser.parseFromString(`${src}<?`, `application/xml`);
      // eslint-disable-next-line
    } catch (_) {}

    if (!(errDoc instanceof XMLDocument) || errDoc.documentElement.getElementsByTagName(`parsererror`).length === errElemCount) {
      return null;
    }
  }

  return doc;
};

const startDateErrorMsg = ({ submitting, startDate }) => (submitting && !startDate) ? 'Debes ingresar fecha de inicio' : '';

const endDateErrorMsg = ({ submitting, startDate, endDate }) => {
  if (submitting && !endDate) {
    return 'Debes ingresar fecha de fin';
  } else if (endDate && (endDate < startDate)) {
    return 'La fecha de término no puede ser anterior a la fecha de inicio';
  }
  return '';
};

// Note: imgFiles debe estar al final del formulario para que lleguen todos los datos
const createForm = data => {
  const form = new FormData();
  let imgFiles = [];
  Object.keys(data).forEach(key => {
    if (data[key] !== undefined) {
      if (Array.isArray(data[key])) {
        if (key === 'imgFiles') {
          imgFiles = data[key];
        } else {
          data[key].forEach(d => form.append(key, d));
        }
      } else {
        form.append(key, data[key]);
      }
    }
  });

  if (imgFiles.length > 0) {
    imgFiles.forEach(image => form.append('imgFile', image, image.filename));
  }

  return form;
};

const enumsToOptions = enumData => Object.keys(enumData).map(key => ({ label: enumData[key], value: key }));

const getMaintenanceStatus = () => {
  const { start, finish } = config.maintenanceDates;
  const startDate = new Date(start);
  const finishDate = new Date(finish);
  const currentDate = new Date();

  const isMaintenanceScheduled = currentDate < startDate;
  const isMaintenanceOn = startDate < currentDate && currentDate < finishDate;
  const isMaintenanceScheduledOrOn = isMaintenanceScheduled || isMaintenanceOn;

  const hrStartDate = moment(startDate).format('dddd DD [de] MMMM [de] YYYY [a las] HH:mm');
  const hrFinishDate = moment(finishDate).format('dddd DD [de] MMMM [de] YYYY [a las] HH:mm');

  return { startDate, finishDate, hrStartDate, hrFinishDate, isMaintenanceScheduled, isMaintenanceOn, isMaintenanceScheduledOrOn };
};

const getComponentOptions = campaignTypes =>
  campaignTypes
    .filter((ct, index, array) => array.findIndex(t => t.componentId === ct.componentId) === index)
    .map(ct => ({ value: ct.componentId, label: ct.componentId }));

const getSamplingUnitOptions = (campaignTypes, selectedComponentValue, selectedCampaignTypeValue) =>
  campaignTypes
    .filter(ct => ct.componentId === selectedComponentValue && ct.mainTypeId === selectedCampaignTypeValue)
    .map(c => ({
      label: (`${c.mainMethodId === 'point-quadrat' ? 'Transecta -' : ''} ${c.mainMethodName}`).trim(),
      value: c.mainMethodId,
    }));

const markerColors = [
  '#3664cb', // blue
  '#00ea00', // green
  '#fabb00', // yellow
  '#eb7800', // orange
  '#c10091', // purple
  '#ff0017', // red
];

// Otros colores usados
// '#26c6da', calypso
// '#5e5e5e', grey
// '#5193eb', nviro

const isNullOrUndefined = v => v === null || v === undefined;

const isEmpty = v => isNullOrUndefined(v) || v === '';

const transformEmptyToNull = v => v === '' ? null : v;

const transformEmptyCoordsPropsToNull = coords => ({
  ...coords,
  accuracy: coords.accuracy === '' ? null : coords.accuracy,
  altitude: coords.altitude === '' ? null : coords.altitude,
});

// para piexifjs que no puede leer de blobs
const blobToDataUrl = async blob => await new Promise(resolve => {
  const reader = new FileReader();
  reader.onload = e => resolve(e.target.result);
  reader.readAsDataURL(blob);
});

// para devolver los datos de piexifjs a blob: https://stackoverflow.com/questions/23150333/html5-javascript-dataurl-to-blob-blob-to-dataurl
const dataUrltoBlob = dataurl => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([ u8arr ], { type: mime });
};

const readExifFromBlob = async img => piexif.load(await blobToDataUrl(img));

const writeLatLongToExif = (exifObj, lat, long) => {
  exifObj.GPS[piexif.GPSIFD.GPSLatitudeRef] = lat < 0 ? 'S' : 'N';
  exifObj.GPS[piexif.GPSIFD.GPSLatitude] = piexif.GPSHelper.degToDmsRational(lat);
  exifObj.GPS[piexif.GPSIFD.GPSLongitudeRef] = long < 0 ? 'W' : 'E';
  exifObj.GPS[piexif.GPSIFD.GPSLongitude] = piexif.GPSHelper.degToDmsRational(long);
  exifObj.GPS[piexif.GPSIFD.GPSMapDatum] = 'WGS-84';
};

const writeDateOriginalToExif = (exifObj, dateString) => {
  exifObj.Exif[piexif.ExifIFD.DateTimeOriginal] = dateString;
};

const exifAndDataUrlToBlob = (exifObj, dataUrlImage) => {
  const exifStr = piexif.dump(exifObj);
  const imageWithExif = piexif.insert(exifStr, dataUrlImage);
  return dataUrltoBlob(imageWithExif);
};

const exifAndBlobToBlob = async (exifObj, imgBlob) => exifAndDataUrlToBlob(exifObj, await blobToDataUrl(imgBlob));

const nviroPalette = [
  '#FFDF00', '#29ABE2', '#ED3F79',
  '#FBB03B', '#C0D72E', '#5BD6E6',
  '#6C757A', '#40474B', '#5193EB',
  '#8BC63D', '#F7931E', '#BD1860',
  '#2F89D1', '#FBCD44', '#ABABAB',
];

// menos colores, pero contrastan mejor
const nviroPaletteB = [
  '#5BD6E6',
  '#2F89D1',
  '#ED3F79',
  '#6C757A',
  '#8BC63D',
  '#F7931E',
  '#FBCD44',
];

const getRandomNviroColor = (palette = nviroPalette) => palette[Math.floor(Math.random() * palette.length)];

const getRandomNviroColors = (count = nviroPaletteB.length) => {

  if (count > nviroPaletteB.length) {
    throw Error (`Paleta insuficiente para cantidad de colores ingresada`);
  }

  const colors = [];
  let length = 0;
  while (length < count) {
    const color = getRandomNviroColor(nviroPaletteB);
    if (!colors.includes(color)) {
      colors.push(color);
      length++;
    }
  }
  return colors;
};

const transparentize = (value, opacity) => {
  const alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return kurkleColor(value).alpha(alpha).rgbString();
};

const darkenize = (value, dark) => {
  const alpha = dark === undefined ? 0.5 : dark;
  return kurkleColor(value).darken(alpha).rgbString();
};

const getFrequency = ({ startDate, endDate }, daysOffset = 0) => {
  const mStartDate = moment(startDate, 'DD-MM-YYY');
  const mEndDate = moment(endDate, 'DD-MM-YYY');
  const days = moment.duration(mEndDate.diff(mStartDate)).asDays();

  const frequency = (
    days < 90 + daysOffset ? 'monthly'
    : days < 120 + daysOffset ? 'trimester'
    : days < 180 + daysOffset ? 'quadmester'
    : days < 365 + daysOffset ? 'semester'
    : 'yearly'
  );

  return frequency;
};

const getMode = arr =>
  arr.sort((a, b) =>
    arr.filter(v => v === a).length - arr.filter(v => v === b).length,
  ).pop();

const getTimeLabel = ({ frequency, date }) => {
  if (frequency === 'monthly') {
    return moment(date, 'DD-MM-YYYY').format('MMMM YYYY');
  } else {
    throw Error (`Frecuencia "${frequency}" no soportada`);
  }
};

const removeNullPropsFromObject = obj => Object.fromEntries(Object.entries(obj).filter(([ , v ]) => v != null));

const deepObjClone = obj => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const clone = {};
  for (const key in obj) {
    clone[key] = deepObjClone(obj[key]);
  }

  return clone;
};

const removeDuplicatedFromArray = array => [ ...new Set(array) ];

const getRandomBetweenRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const generateDebouncer = ({ callback, waitMs = 400, timeoutRef }) => {
  const debouncer = (...args) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      clearTimeout(timeoutRef.current);
      await callback(...args);
    }, waitMs);
  };

  debouncer.cancel = () => clearTimeout(timeoutRef.current);
  return debouncer;
};

const thousandsDotSeparator = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

const thousandsDotAndDecimalComma = number => {
  const [ integer, decimal ] = number.toString().split('.');
  return `${thousandsDotSeparator(integer)},${decimal}`;
};

const getIsNviroOrCswEmployee = email => email.endsWith('@nviro.cl') || email.endsWith('@csw.cl');

const getCompanyLetters = companyName => {
  if (companyName) {
    const [ firstLetter, secondLetter = ' ' ] = companyName.split(' ');
    return `${firstLetter[0].toUpperCase()}${secondLetter[0].toUpperCase().trim()}`;
  }
  return false;
};

const getDayIgnoreTZ = date => moment.utc(date).format('DD-MM-YYYY');


export {
  groupByObjKey,
  getRandomColor,
  addOrReplaceOpacityInColor,
  formatDate,
  inverseFormatDate,
  formatDateExtension,
  humanReadableFormatDate,
  humanReadableFormatDateWithHour,
  humanReadableDay,
  objectIsEmpty,
  arraysAreEqual,
  humanReadableArrayOfStrings,
  objectsAreEqual,
  optionsToObject,
  truncateWithEllipsis,
  humanReadableMinSec,
  humanReadableHrMin,
  tryParseXml,
  startDateErrorMsg,
  endDateErrorMsg,
  createForm,
  enumsToOptions,
  getMaintenanceStatus,
  getComponentOptions,
  getSamplingUnitOptions,
  markerColors,
  isEmpty,
  isNullOrUndefined,
  transformEmptyToNull,
  transformEmptyCoordsPropsToNull,
  blobToDataUrl,
  readExifFromBlob,
  writeLatLongToExif,
  writeDateOriginalToExif,
  exifAndBlobToBlob,
  getRandomNviroColor,
  getRandomNviroColors,
  transparentize,
  darkenize,
  getFrequency,
  getMode,
  getTimeLabel,
  removeNullPropsFromObject,
  deepObjClone,
  removeDuplicatedFromArray,
  getRandomBetweenRange,
  dayNames,
  monthNames,
  prependZero,
  generateDebouncer,
  getMonthName,
  thousandsDotSeparator,
  thousandsDotAndDecimalComma,
  getIsNviroOrCswEmployee,
  getCompanyLetters,
  getDayIgnoreTZ,
};