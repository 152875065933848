import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ZoomOutMap, MyLocation } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { MapWrapper } from 'src/components';
import { mapOptions, layers } from 'src/scenes/Home/scenes/CoverPage/components/floraMapUIDataFaker';
import { CloudDownloadIcon, LayersIcon, Earth } from 'src/utils/mdIcons';


const useStyles = makeStyles(theme => ({
  mapContainer: {
    position: 'relative',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  map: {
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
    height: '450px',
  },
  tools: {
    position: 'absolute',
    top: 0,
    zIndex: 314159,
    pointerEvents: 'initial',
  },
  tool: {
    borderRadius: '3px',
    backgroundColor: theme.palette.flora.primary.main,
    color: theme.palette.common.white,
  },
  actions: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: 314159,
    pointerEvents: 'initial',
  },
  action: {
    borderRadius: '25px',
    backgroundColor: theme.palette.flora.primary.main,
    color: theme.palette.common.white,
  },
  positionIcon: {
    position: 'absolute',
    right: '50%',
    top: '30%',
    zIndex: 314160,
  },
  circle: {
    border: '2px solid white',
    height: '14px',
    width: '14px',
    borderRadius: '25px',
    backgroundColor: theme.palette.primary.main,
    zIndex: 314162,
  },
  triangle: {
    width: 0,
    height: 0,
    borderLeft: `14px solid ${theme.palette.primary.main}`,
    borderTop: '7px solid transparent',
    borderBottom: '7px solid transparent',
    zIndex: 314161,
    transform: 'translate(-3px, 0px)',
  },
  triangleOrientation: {
    transform: 'rotate(90deg)',
  },
}));

const FloraAppMapUI = () => {
  const classes = useStyles();
  return (
    <Box className={classes.mapContainer}>
      <Box p={1} className={classes.tools} display="flex" flexDirection="column" >
        <Box mb={1} p={0.5} className={classes.tool} display="flex" alignItems="center" justifyContent="center">
          <Earth fontSize="small" />
        </Box>
        <Box mb={1} p={0.5} className={classes.tool} display="flex" alignItems="center" justifyContent="center">
          <LayersIcon fontSize="small" />
        </Box>
        <Box p={0.5} className={classes.tool} display="flex" alignItems="center" justifyContent="center">
          <CloudDownloadIcon fontSize="small" />
        </Box>
      </Box>
      <MapWrapper id="explore-plots" className={classes.map} options={mapOptions} layers={layers} disableZoom />
      <Box className={classes.positionIcon} display="flex" flexDirection="column" alignItems="center">
        <Box className={classes.circle}></Box>
        <Box className={classes.triangleOrientation}>
          <Box className={classes.triangle}></Box>
        </Box>
      </Box>
      <Box p={1} className={classes.actions} display="flex" flexDirection="column" >
        <Box mb={1} p={1} className={classes.action} display="flex" alignItems="center" justifyContent="center">
          <ZoomOutMap />
        </Box>
        <Box p={1} className={classes.action} display="flex" alignItems="center" justifyContent="center">
          <MyLocation />
        </Box>
      </Box>
    </Box>
  );
};

FloraAppMapUI.propTypes = {
  syncedColor: PropTypes.bool,
  campaignData: PropTypes.object,
};


export {
  FloraAppMapUI,
};