import urlJoin from 'url-join';

import { isAdmin } from 'src/utils/checkers';
import config from 'src/config/local';

const { serverUrl } = config;

const notRedirectUrls = [
  'users/reset-password',
  'users/authenticate',
  'users/authenticate-accepting-terms-and-policy',
  'users/verification-token-validity',
  'users/verify-email',
  'company/selectables',
];

const request = async ({ baseUrl, url, method = 'POST', data = {}, options = {} }) => {
  try {
    const finalUrl = new URL(url.match(/^https?:\/\//) ? url : urlJoin(serverUrl, baseUrl, url));
    const requestOptions = {
      method,
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      credentials: 'include',
      ...options,
    };

    if (method.toLowerCase() === 'get') {
      // Note: no se soportan arreglos dentro del objeto, por lo que se permite enviar el string directamente
      finalUrl.search = typeof data === 'object' ? new URLSearchParams(data) : data;
    } else {
      if (requestOptions.headers['Content-Type'] === 'multipart/form-data') {
        requestOptions.body = data;
        delete requestOptions.headers['Content-Type'];
      } else {
        requestOptions.body = JSON.stringify(data);
      }
    }
    const resp = await fetch(finalUrl, requestOptions, baseUrl = 'api');

    const contentType = resp.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      const jsonResp = await resp.json();
      if (localStorage.getItem('maintenance')) {
        localStorage.removeItem('maintenance');
        window.dispatchEvent(new Event('server-maintenance'));
      }
      if (200 <= resp.status && resp.status < 400) {
        return { ...jsonResp, httpStatus: resp.status };
      } else if (resp.status === 401 && !notRedirectUrls.includes(url)) {
        localStorage.clear();
        window.location.href = '/login?needLogin=true';
      } else if (resp.status === 403 && jsonResp.errorCode === 'UNVERIFIED_EMAIL') {
        window.location.href = '/web/user';
      } else if (resp.status === 403 && jsonResp.errorCode === 'INVALID_SUBSCRIPTION') {
        const companyRole = JSON.parse(localStorage.getItem('company')).role;
        if (isAdmin(companyRole)) {
          window.location.href = '/web/company/subscription';
        } else {
          window.location.href = '/web/expired';
        }
      } else if (resp.status === 404) {
        window.location.href = '/';
      } else if (resp.status === 503) {
        localStorage.setItem('maintenance', 'on');
        window.dispatchEvent(new Event('server-maintenance'));
        if (window.location.pathname.match(/^\/web(?=$|\/)/)) {
          window.location.href = '/maintenance';
        }
      }
      return Promise.reject({ serverMessage: jsonResp.message, httpStatus: resp.status, data: jsonResp.data });
    } else if (contentType && contentType.includes('application/pdf')) {
      const blob = await resp.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'factura.pdf';
      document.body.appendChild(a);
      a.click();
      a.remove();
      return { message: 'PDF descargado correctamente', httpStatus: resp.status };
    } else {
      const textResp = await resp.text();
      console.log(textResp);
      return Promise.reject({ message: 'Error en el tipo de datos esperado' });
    }
  } catch (e) {
    console.log(e);
    if (e.code === 20 && e.message === 'The user aborted a request.') {
      return Promise.reject({ message: 'Se canceló la petición de datos' });
    }
    return Promise.reject({ message: 'Error al intentar comunicarse con el servidor. Por favor revise su conexión o inténtelo más tarde' });
  }
};

const requestMethod = method => (url, data, options, baseUrl = 'api') => request({ url, method, data, options, baseUrl });
const get = requestMethod('GET');
const post = requestMethod('POST');
const patch = requestMethod('PATCH');
const del = requestMethod('DELETE');


export {
  get,
  post,
  patch,
  del,
};
