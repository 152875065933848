import React, { useEffect, useState } from 'react';
import { Grid, Box, Paper, Typography, Zoom, FormHelperText, useMediaQuery, FormControlLabel, Checkbox } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Error } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { NviroButton, EmailVerificationRetryNote } from 'src/components';
import { CoolBackground, NviroTextField } from 'src/scenes/Home/components';
import { usersApi } from 'src/services';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';


const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
    },
  },
  grid: {
    zIndex: 0,
  },
  paper: {
    paddingRight: theme.spacing(12),
    paddingLeft: theme.spacing(12),
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(9),
    width: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  title: {
    paddingBottom: theme.spacing(4),
    color: theme.palette.common.gray600,
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(0),
    },
  },
  registerText: {
    textTransform: 'uppercase',
    fontWeight: 600,
    cursor: 'pointer',
  },
  logo: {
    marginTop: theme.spacing(8) * -1,
    paddingBottom: theme.spacing(3),
    cursor: 'pointer',
    height: '80px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      height: '72px',
    },
  },
  helperText: {
    fontSize: '0.875rem',
  },
  formInput: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingBottom: theme.spacing(0),
      marginBottom: theme.spacing(1),
    },
  },
  termsText: {
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      fontSize: '0.875rem',
    },
  },
  confirmButton: {
    width: '360px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  inputText: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  link: {
    pointerEvents: 'auto',
    color: theme.palette.nviro.primary.main,
    '&:visited': {
      color: theme.palette.nviro.primary.dark,
    },
    '&:hover': {
      filter: 'brightness(1.2)',
    },
  },
}));


const RegisterContainer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [ form, setForm ] = useState(
    { firstName: '', lastName: '', email: '', password: '', repassword: '', termsAndPolicyAccepted: false },
  );

  const { NOT_STARTED, LOADING, SUCCESS, ERROR, BAD_DATA } = fetchStatusEnum;
  const [ submitStatus, setSubmitStatus ] = useState(NOT_STARTED);
  const [ message, setMessage ] = useState();

  useEffect(() => {
    document.title = 'Registro - Nviro Capture';
    const redirectIfUserIsAuthenticated = async () => {
      const isAuthenticated = await usersApi.isAuthenticated();
      if (isAuthenticated) {
        history.push('web');
      }
    };

    redirectIfUserIsAuthenticated();

    // eslint-disable-next-line
  }, []);

  const keyDownIsEnter = e => e.key === 'Enter';

  const { firstName, lastName, email, password, repassword, termsAndPolicyAccepted } = form;

  const handleKeyDown = e => {
    if (keyDownIsEnter(e)) {
      handleSubmit();
    }
  };

  const handleChange = e => setForm({ ...form, [e.target.name]: e.target.value });

  const handleSubmit = async () => {
    if (submitStatus === LOADING) {
      return;
    }
    setSubmitStatus(LOADING);
    try {
      if (!firstName || !lastName || !email || !password || password.length < 8 || password !== repassword || !termsAndPolicyAccepted) {
        setSubmitStatus(BAD_DATA);
        return;
      }

      const newUser = { firstName, lastName, email, password, termsAndPolicyAccepted };
      await usersApi.registerUser(newUser);
      const userCredentials = { email, password };
      const { lastCompanyVisited, ...user } = await usersApi.authenticate(userCredentials);
      if (user) {
        setSubmitStatus(SUCCESS);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('company', JSON.stringify(lastCompanyVisited));
      } else {
        setSubmitStatus(ERROR);
        setMessage('Problemas para registrarse, intente más tarde.');
      }
    } catch (e) {
      setSubmitStatus(ERROR);
      setMessage(e.serverMessage || 'Problemas para registrarse, intente más tarde.');
    }
  };

  const otherOptions = {
    className: classes.inputText,
    color: 'secondary',
    required: true,
    onChange: handleChange,
    FormHelperTextProps: { component: 'div' },
  };
  const isLoadingOrBadData = (submitStatus === LOADING || submitStatus === BAD_DATA);
  return (
    <Grid className={classes.container} direction="column" container alignItems="center" justifyContent="center">
      <CoolBackground />
      <Grid item className={classes.grid}>
        <img className={classes.logo} src={theme.whiteLogo} alt="nviro-logo" onClick={() => history.push('/')} />
      </Grid>
      <Grid item container justifyContent="center" className={classes.grid}>
        <Paper className={classes.paper} elevation={5} onKeyDown={handleKeyDown}>
          { submitStatus !== SUCCESS && <>
            <Typography className={classes.title}>Crea una cuenta personal</Typography>
            <Box display="flex" width="100%" className={classes.formInput}>
              <NviroTextField
                size={isDownSm ? 'small' : 'medium'}
                label="Nombre"
                name="firstName"
                value={firstName}
                autoFocus
                {...otherOptions}
                helperText={isLoadingOrBadData && !firstName &&
                  <Box display="flex" alignItems="center" component="span">
                    <Box mr={1}><Error/></Box>
                    <Typography display="initial" className={classes.helperText}>Debes ingresar un nombre</Typography>
                  </Box>
                }
                error={isLoadingOrBadData && !firstName}
              />
              <Box width={theme.spacing(8)} />
              <NviroTextField
                size={isDownSm ? 'small' : 'medium'}
                label="Apellido"
                name="lastName"
                value={lastName}
                {...otherOptions}
                helperText={isLoadingOrBadData && !lastName &&
                  <Box display="flex" alignItems="center" component="span">
                    <Box mr={1}><Error/></Box>
                    <Typography display="initial" className={classes.helperText}>Debes ingresar un apellido</Typography>
                  </Box>
                }
                error={isLoadingOrBadData && !lastName}
              />
            </Box>
            <Box className={classes.formInput} width="100%">
              <NviroTextField
                size={isDownSm ? 'small' : 'medium'}
                label="Correo electrónico"
                name="email"
                value={email}
                {...otherOptions}
                helperText={isLoadingOrBadData && !email &&
                  <Box display="flex" alignItems="center" component="span">
                    <Box mr={1}><Error/></Box>
                    <Typography display="initial" className={classes.helperText}>Debes ingresar un correo</Typography>
                  </Box>
                }
                error={isLoadingOrBadData && !email}
              />
            </Box>
            <Box display="flex" width="100%" flexDirection="column">
              <Box display="flex" className={classes.formInput}>
                <NviroTextField
                  size={isDownSm ? 'small' : 'medium'}
                  label="Contraseña"
                  name="password"
                  type="password"
                  value={password}
                  {...otherOptions}
                  error={isLoadingOrBadData && (!password || password.length < 8)}
                  helperText={isLoadingOrBadData && (!password || password.length < 8) ?
                    <Box display="flex" alignItems="center" component="span">
                      <Box mr={1}><Error/></Box>
                      <Typography display="initial" className={classes.helperText}>
                        {!password && 'Debes ingresar una contraseña'}
                        {password && password.length < 8 && 'La contraseña debe tener 8 o más caracteres'}
                      </Typography>
                    </Box> :
                    submitStatus === NOT_STARTED &&
                      <Box ml={-1}>
                        <FormHelperText className={classes.helperText}>Utiliza ocho caracteres como mínimo</FormHelperText>
                      </Box>
                  }
                />
                <Box width={theme.spacing(8)} />
                <NviroTextField
                  size={isDownSm ? 'small' : 'medium'}
                  label="Confirmar contraseña"
                  name="repassword"
                  type="password"
                  value={repassword}
                  {...otherOptions}
                  error={isLoadingOrBadData && password !== repassword}
                  helperText={isLoadingOrBadData && password !== repassword &&
                    <Box display="flex" alignItems="center" component="span">
                      <Box mr={1}><Error/></Box>
                      <Typography display="initial" className={classes.helperText}>
                        Las contraseñas deben coincidir
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </Box>
            <Box width="100%" className={classes.formInput} mt={2} pl={1}>
              <FormControlLabel required style={{ pointerEvents: 'none' }}
                control={ <Checkbox checked={ termsAndPolicyAccepted } style={{ pointerEvents: 'auto' }}
                  onChange={ e => setForm({ ...form, termsAndPolicyAccepted: e.target.checked }) }
                /> }
                label={<Typography className={classes.termsText}>
                  Acepto los <Link className={classes.link} target="_blank" to='/terms'>Términos de Uso</Link> y
                  las <Link className={classes.link} target="_blank" to='/privacy'>Políticas de Privacidad</Link>
                </Typography>}
              />
              { isLoadingOrBadData && !termsAndPolicyAccepted &&
                <Box display="flex" alignItems="center" component="span" className={classes.errorMessage}>
                  <Box mr={1}><Error/></Box>
                  <Typography display="initial" className={classes.helperText}>
                    Debes aceptar para poder registrarte.
                  </Typography>
                </Box>
              }
            </Box>
            <Box className={classes.confirmButton} mt={{ xs: 4, md: 2 }}>
              <NviroButton
                size={isDownSm ? 'small' : 'medium'}
                loading={submitStatus === LOADING}
                success={submitStatus === SUCCESS}
                onClick={handleSubmit}
              >
                Confirmar
              </NviroButton>
            </Box>
            <Box pt={2}>
              {submitStatus === ERROR &&
                <Zoom in>
                  <Alert severity="error">{message}</Alert>
                </Zoom>
              }
            </Box>
          </>}
          { submitStatus === SUCCESS &&
            <Zoom in>
              <Box>
                <Alert severity="success">
                  ¡Gracias por registrarte en Nviro! Te hemos enviado un correo con instrucciones para que
                  verifiquemos que { form.email } es tu email y así termines tu registro. Ya puedes ver tu
                  perfil <Link className={classes.link} to='/web/user'>aquí</Link>.
                </Alert>
                <EmailVerificationRetryNote px={isDownSm ? 1 : 2} mt={6}></EmailVerificationRetryNote>
              </Box>
            </Zoom>
          }
        </Paper>
      </Grid>
    </Grid>
  );
};



export {
  RegisterContainer,
};