import React from 'react';
import { Box, Typography, Paper, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { LabelChip } from 'src/components';
import { thousandsDotSeparator as tds, formatDate, getMonthName } from 'src/utils/util';
import { upperCaseFirstLetter as ucfl } from 'src/utils/formatters';


const useStyles = makeStyles(theme => ({
  billingPaper: {
    height: '100%',
    width: '100%',
  },
  billingTitle: {
    fontWeight: 500,
  },
  activeUsersProgress: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    background: 'none',
    border: `1px solid ${theme.palette.common.gray400}`,
  },
  billingLinks: {
    borderLeft: `1px solid ${theme.palette.common.gray400}`,
    marginLeft: theme.spacing(2),
  },
}));

const PendingBillingCard = ({ paymentPreview, currentPlan }) => {
  const classes = useStyles();

  const { fixedTotalNetAmount, extraUsersTotalNetAmount, extraGbsTotalNetAmount, prevSubDiscountNetAmount,
    ivaAmount, totalAmount, month, year, activeUsers } = paymentPreview;

  const dayOneInNextMonth = formatDate(new Date(paymentPreview.year, paymentPreview.month) + 1, 1);
  const hasDiscount = prevSubDiscountNetAmount > 0;

  return (
    <Paper className={classes.billingPaper}>
      <Box p={2} display="flex" flexDirection="column" height="100%">
        <Box display="flex" mb={0.5} flexDirection="column">
          <Typography variant="h6" className={classes.billingTitle}>
            {ucfl(getMonthName(month))} de {year}
          </Typography>
          <Typography variant="caption" color="textSecondary">
              Fecha de pago {dayOneInNextMonth}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row">
          <LabelChip
            color="default"
            variant="outlined"
            label={`Usuarios activos | ${activeUsers} de ${currentPlan?.usersLimit}`}
          />
        </Box>
        <Box mt={2} display="flex" alignItems="baseline">
          <Box display="flex" flexDirection="column">
            <Typography variant="caption">Plan base: ${tds(fixedTotalNetAmount)}</Typography>
            {hasDiscount && <Typography variant="caption">
              Dscto suscripción anterior: - ${tds(prevSubDiscountNetAmount)}
            </Typography>}
            <Typography variant="caption">Usuarios extras: ${tds(extraUsersTotalNetAmount)}</Typography>
            <Typography variant="caption">GBs extras: ${tds(Math.floor(extraGbsTotalNetAmount))}</Typography>
            <Typography variant="caption">Total IVA: ${tds(Math.floor(ivaAmount))}</Typography>
            <Box mb={0.5}>
              <Divider />
            </Box>
            <Typography variant="body1">Total a pagar: {tds(totalAmount)} CLP</Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

PendingBillingCard.propTypes = {
  paymentPreview: PropTypes.shape({
    fixedTotalNetAmount: PropTypes.number.isRequired,
    extraUsersTotalNetAmount: PropTypes.number.isRequired,
    extraGbsTotalNetAmount: PropTypes.number.isRequired,
    prevSubDiscountNetAmount: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]),
    ivaAmount: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired,
    year: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    activeUsers: PropTypes.number.isRequired,
  }).isRequired,
  currentPlan: PropTypes.shape({
    usersLimit: PropTypes.number.isRequired,
  }).isRequired,
};


export { PendingBillingCard };