import React, { useEffect, useMemo, useRef } from 'react';
import { useMediaQuery } from '@material-ui/core';
import L from 'leaflet';
import config from 'src/config/local';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { renderToString } from 'react-dom/server';
import { Help } from '@material-ui/icons';

import { LayersPlus, MapMarkerPlus } from 'src/utils/mdIcons';
import { getMarkerIcon } from 'src/components/map/mapHelper';


const useStyles = makeStyles(() => ({
  map: {
    position: 'relative',
    width: '100%',
    height: ({ height }) => `${height}px`,
  },
}));

const { satelliteStreetsBaseMap, outdoorsBaseMap } = config;

const addTools = map => {
  L.control.layers(baseMaps).addTo(map).setPosition('topleft');

  const drawnItems = new L.FeatureGroup();
  map.addLayer(drawnItems);
  const drawControl = new L.Control.Draw({
    position: 'topleft',
    draw: {
      rectangle: false,
      circlemarker: false,
      polyline: false,
      polygon: true,
      circle: false,
      marker: {
        icon: getMarkerIcon(5),
        repeatMode: true,
      },
    },
  });
  map.addControl(drawControl);

  const iconStyle = { color: '#464646' };

  const kmlImportPointsToolButton = L.easyButton({
    id: 'import-points-btn',
    position: 'topleft',
    type: 'replace',
    states: [ {
      title: 'Importar puntos (KML/KMZ)',
      icon: renderToString(<MapMarkerPlus fontSize="small" style={iconStyle} />),
    } ],
  });
  kmlImportPointsToolButton.addTo(map);

  const kmlImportAreaToolButton = L.easyButton({
    id: 'import-area',
    position: 'topleft',
    type: 'replace',
    states: [ {
      title: 'Importar área de interés (KML/KMZ)',
      icon: renderToString(<LayersPlus fontSize="small" style={iconStyle} />),
    } ],
  });
  kmlImportAreaToolButton.addTo(map);

  const helpButton = L.easyButton({
    id: 'help-btn',
    position: 'topleft',
    type: 'replace',
    states: [ {
      title: 'Ayuda',
      icon: renderToString(<Help fontSize="small" style={iconStyle} />),
    } ],
  });
  helpButton.addTo(map);
};

const baseMaps = {
  'Híbrido': L.tileLayer(satelliteStreetsBaseMap, {}),
  'Topográfico': L.tileLayer(outdoorsBaseMap, {}),
};

const MapWrapper = props => {
  const {
    id = 'map', className, options, layers = [], legend, showTools = false,
    disableZoom = false, height = 100, events, mapRef: ref, fitBoundsOnUpdate = false,
    showBaseMaps = false,
  } = props;

  const classes = useStyles({ height });
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const mapRef = useMemo(() => ref || {}, [ ref ]);

  const mainLayerRef = useRef(new L.FeatureGroup());
  const mainLayer = mainLayerRef.current;

  const defaultOptions = {
    center: [ -33.4679997, -70.7003513 ],
    zoom: 5,
    layers: [
      baseMaps['Híbrido'],
    ],
  };

  useEffect(() => {
    mapRef.current = L.map(id, { ...defaultOptions, ...options });
    const map = mapRef.current;

    if (showTools) {
      addTools(map);
    }

    if (events) {
      const eventsObj = events(map);
      Object.keys(eventsObj).forEach(key => map.on(key, () => eventsObj[key]()));
    }

    if (showBaseMaps && !showTools) {
      L.control.layers(baseMaps).addTo(map).setPosition('topright');
    }

    map.addLayer(mainLayer);

    if (disableZoom && map.zoomControl) {
      map.zoomControl.disable();
    }

    return () => map.remove();

    // eslint-disable-next-line
  }, [ ]);

  useEffect(() => {
    const map = mapRef.current;
    mainLayer.clearLayers();
    const tileLayers = [];
    if (layers.length > 0) {
      layers.forEach(layer => {
        if (!layer._url) {
          mainLayer.addLayer(layer);
        } else {
          tileLayers.push(layer);
        }
      });
    }

    if (legend) {
      legend.addTo(map);
    }

    if (fitBoundsOnUpdate && layers.length) {
      const bounds = mainLayer.getBounds();
      map.fitBounds(bounds);
    }
    tileLayers.forEach(layer => {
      mainLayer.addLayer(layer);
    });
    // eslint-disable-next-line
  }, [ layers ]);

  useEffect(() => {
    if (isDownSm && mapRef.current.zoomControl) {
      mapRef.current.removeControl(mapRef.current.zoomControl);
    }
  }, [ mapRef, isDownSm ]);

  return (
    <div id={id} className={clsx(classes.map, className)} />
  );
};

MapWrapper.propTypes = {
  className: PropTypes.string,
  options: PropTypes.object,
  id: PropTypes.string,
  layers: PropTypes.array,
  legend: PropTypes.object,
  showTools: PropTypes.bool,
  showBaseMaps: PropTypes.bool,
  disableZoom: PropTypes.bool,
  fitBoundsOnUpdate: PropTypes.bool,
  height: PropTypes.number,
  events: PropTypes.func,
  mapRef: PropTypes.object,
};


export { MapWrapper };