import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { LabelChip } from 'src/components';
import { getMonthName } from 'src/utils/util';
import { thousandsDotSeparator as tds } from 'src/utils/util';


const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
  },
}));

const PaymentCard = ({ planName, amount, billingMonth, billingYear }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" flexGrow={1} p={1}>
      <Box px={1}>
        <Typography noWrap className={classes.title} variant="h6" color="textPrimary">
          Factura {getMonthName(billingMonth)} de {billingYear}
        </Typography>
      </Box>
      <Box px={1}>
        <LabelChip label={`Plan | ${planName}`} color="common.gray800" />
      </Box>
      <Box px={1}>
        <LabelChip className={classes.labelDate} color="default" variant="outlined" label={`Monto | $${tds(Math.floor(amount))}`} />
      </Box>
    </Box>
  );
};

PaymentCard.propTypes = {
  planName: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  billingMonth: PropTypes.string.isRequired,
  billingYear: PropTypes.string.isRequired,
};


export { PaymentCard };