import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Box, IconButton, Typography, Paper, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { ArrowBack } from '@material-ui/icons';

import { TitleContainer, ContentContainer, DialogWrap, SwitchIOS } from 'src/components';
import { featuresByPlan } from 'src/scenes/Home/scenes/Pricing/pricingData';
import { CheckIcon, CloseIcon } from 'src/utils/mdIcons';
import { SubscriptionCreateOrUpdateDialog, SubscriptionPlan, SubscriptionSuccessDialog, CustomSubscriptionPlan }
  from 'src/scenes/CompanySettings/scenes/SubscriptionPlans/components';
import { payApi } from 'src/services';
import checkers from 'src/utils/checkers';


const useStyles = makeStyles(theme => ({
  content: {
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
  },
  title: {
    fontWeight: 500,
  },
  icon: {
    fontWeight: 'bold',
  },
  closeIcon: {
    color: theme.palette.error.main,
  },
  planContainer: {
    position: 'sticky',
    top: theme.spacing(10),
  },
  planFeat: {
    textAlign: 'center',
    borderLeft: `1px solid ${theme.palette.common.gray400}`,
  },
  feat: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  featName: {
    textTransform: 'uppercase',
  },
  billedSwitch: {
    alignContent: 'flex-end',
  },
  offer: {
    fontWeight: ({ isYearly }) => isYearly ? 500 : 400,
  },
}));

const SubscriptionPlansContainer = () => {

  const [ creditCard, setCreditCard ] = useState();
  const [ billingData, setBillingData ] = useState();
  const [ subscription, setSubscription ] = useState(null);
  const [ plans, setPlans ] = useState([]);
  const [ customPlan, setCustomPlan ] = useState(null);
  const [ isYearly, setIsYearly ] = useState(false);
  const [ hasUnpaidBillingCycles, setHasUnpaidBillingCycles ] = useState(false);

  const classes = useStyles({ isYearly });
  const history = useHistory();

  const [ dialog, setDialog ] = useState({ isOpen: false, data: {}, type: '' });

  const closeDialog = () => setDialog({ isOpen: false, type: '' });
  const openDialog = (type, data) => setDialog({ isOpen: true, type, data });
  const openChangeSubscriptionDialog = data => openDialog('change', data);
  const openSuccessfulSubscriptionDialog = () => openDialog('successful-subscription');

  const getDialogType = () => {
    switch (dialog.type) {
      case 'change': {
        const selectedPlan = plans.find(plan => dialog.data === plan.id);
        return <SubscriptionCreateOrUpdateDialog
          selectedPlan={selectedPlan}
          creditCard={creditCard}
          billingData={billingData}
          currentSubscription={subscription}
          isSubCreation={isSubCreation}
          actions={{
            closeDialog,
            goToSubscriptionUrl,
            subscribePlan: ({ selectedFrequency, subStart }) =>
              subscribePlan({ planId: selectedPlan.id, billingFrequencyId: selectedFrequency, subStart }),
          }}
          isYearlySelected={isYearly}
        />;
      }
      case 'successful-subscription':
        return <SubscriptionSuccessDialog actions={{ closeDialog }} />;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const [ billing, subscription, plans, customPlan, pendingPayments, creditCard ] = await Promise.all([
        payApi.getBillingData(),
        payApi.getSubscription(),
        payApi.getCompanyPlans(),
        payApi.getCompanyCustomPlan(),
        payApi.getPendingPayments(),
        payApi.getCreditCard(),
      ]);
      setCreditCard(creditCard);
      const hasUnpaidBillingCycles = pendingPayments.length >= 2 && !checkers.isTrialAccount(subscription);
      setHasUnpaidBillingCycles(hasUnpaidBillingCycles);
      setSubscription(subscription);
      setIsYearly(subscription.billingFrequencyId === 'annual');
      setBillingData(billing);
      const hasAvailableCustomPlan = Boolean(customPlan);
      if (hasAvailableCustomPlan) {
        customPlan.name = 'Personalizado';
        customPlan.description = 'Para empresas con requerimientos específicos';
      }
      setPlans([ ...plans, ...(hasAvailableCustomPlan ? [ customPlan ] : []) ]);
      setCustomPlan(customPlan);
    };
    fetchData();
  // eslint-disable-next-line
  }, []);

  const subscriptionUrl = '/web/company/subscription';

  const subscribePlan = async ({ planId, billingFrequencyId, subStart }) => {
    const subscription = await payApi.createSubscription({ planId, billingFrequencyId, subStart });
    setSubscription(subscription);
    setIsYearly(subscription.billingFrequencyId === 'annual');
    openSuccessfulSubscriptionDialog();
  };

  const goToSubscriptionUrl = () => history.push(subscriptionUrl);

  const leftSideWidth = '216px';

  const isExpiredSub = checkers.isExpiredSub(subscription?.statusId);
  const hasAvailableCustomPlan = Boolean(customPlan);
  const hasPreActiveSub = Boolean(subscription?.nextSubscription);
  const isSubCreation = (checkers.isTrialAccount(subscription) && !hasPreActiveSub) || isExpiredSub;

  return (
    <>
      <Grid container>
        <TitleContainer maxWidth="xl" breadcrumbs={[ 'company', { name: `Suscripción`, url: subscriptionUrl }, { name: 'Planes' } ]}>
          <Box display="flex" alignItems="center">
            <IconButton className={classes.backArrow} onClick={goToSubscriptionUrl}>
              <ArrowBack fontSize='large' color="inherit" />
            </IconButton>
            Planes de suscripción
          </Box>
        </TitleContainer>
        <ContentContainer maxWidth="lg" className={classes.content}>
          <Box pb={1}>
            <Box pt={2} className={classes.planContainer} display="flex">
              <Box display="flex" flexDirection="column" alignSelf="flex-end">
                <Box mb={1} minWidth={leftSideWidth} display="flex">
                  <SwitchIOS color="secondary" checked={isYearly} onChange={() => setIsYearly(ps => !ps)} />
                  <Box display="flex" flexDirection="column" pr={2}>
                    <Typography className={classes.offer} variant="body2" noWrap>Paga anualmente y</Typography>
                    <Typography className={classes.offer} color="primary" variant="body2">ahorra un 15%</Typography>
                  </Box>
                </Box>
                <Typography variant="caption">*** Los valores no incluyen IVA</Typography>
              </Box>

              <Grid container spacing={1}>
                {plans.map(plan =>
                  <SubscriptionPlan
                    key={plan.id}
                    isYearly={isYearly}
                    plan={plan}
                    isCurrentPlan={subscription?.planId === plan.id && !isExpiredSub}
                    subscription={subscription}
                    openChangeSubscriptionDialog={openChangeSubscriptionDialog}
                    isSubCreation={isSubCreation}
                    hasUnpaidBillingCycles={hasUnpaidBillingCycles}
                  />,
                )}
                {!hasAvailableCustomPlan && <CustomSubscriptionPlan plan={{ name: 'Personalizado' }} />}
              </Grid>
            </Box>
            <Box pb={2}>
              {Object.keys(featuresByPlan).map(key =>
                <Paper elevation={0} className={classes.feat} key={key}>
                  <Box px={4} py={2}>
                    <Typography className={classes.featName} color="textPrimary" variant="h6">{key}</Typography>
                    <Box>
                      <Divider />
                    </Box>
                  </Box>
                  {featuresByPlan[key].map(({ name, plan }) =>
                    <Fragment key={`${name}-${key}`}>
                      <Box display="flex">
                        <Box minWidth={leftSideWidth} py={2} pl={4}>
                          <Typography variant="body2" color="textPrimary">{name}</Typography>
                        </Box>
                        <Grid container alignItems="center" justifyContent="center">
                          {[ 1, 2, 3, 4 ].map(planId =>
                            <Grid item xs={3} className={classes.planFeat} key={`plan-${planId}`}>
                              <Box py={2}>
                                {typeof plan[planId] === 'boolean' ?
                                  (plan[planId] ?
                                    <CheckIcon className={classes.icon} color="primary" />
                                    : <CloseIcon className={classes.closeIcon} />
                                  )
                                  : plan[planId]
                                }
                              </Box>
                            </Grid>,
                          )}
                        </Grid>
                      </Box>

                    </Fragment>,
                  )}
                </Paper>,
              )}
            </Box>
          </Box>
        </ContentContainer>
        <DialogWrap maxWidth="md" fullWidth onClose={closeDialog} aria-labelledby="form-dialog-title" open={dialog.isOpen}>
          {getDialogType()}
        </DialogWrap>
      </Grid>
    </>
  );
};


export { SubscriptionPlansContainer };
