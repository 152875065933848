import React from 'react';
import { Box, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { InfoTooltip } from 'src/components';
import { ActiveUsersProgress } from 'src/scenes/CompanySettings/scenes/Subscription/components';
import { thousandsDotSeparator as tds, formatDate, getMonthName } from 'src/utils/util';
import { upperCaseFirstLetter as ucfl } from 'src/utils/formatters';


const useStyles = makeStyles(theme => ({
  billingPaper: {
    height: '100%',
    width: '100%',
    maxWidth: '480px',
    minWidth: '320px',
  },
  billingTitle: {
    fontWeight: 500,
  },
  activeUsersProgress: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    background: 'none',
    border: `1px solid ${theme.palette.common.gray400}`,
  },
  billingLinks: {
    borderLeft: `1px solid ${theme.palette.common.gray400}`,
    marginLeft: theme.spacing(2),
  },
}));

const BillingCard = ({ paymentPreview, currentPlan }) => {
  const classes = useStyles();

  const { fixedTotalNetAmount, extraUsersTotalNetAmount, extraGbsTotalNetAmount, prevSubDiscountNetAmount,
    ivaAmount, totalAmount, month, year, activeUsers } = paymentPreview;

  const dayOneInNextMonth = formatDate(new Date(paymentPreview.year, paymentPreview.month) + 1, 1);

  const hasDiscount = prevSubDiscountNetAmount > 0;

  return (
    <Paper className={classes.billingPaper}>
      <Box p={2} display="flex" flexDirection="column" height="100%">
        <Box mb={0.5} display="flex" justifyContent="space-between">
          <Box display="flex">
            <Typography variant="body2" className={classes.billingTitle}>
                Tu siguiente pago
            </Typography>
            <InfoTooltip contents={
              <Box display="flex" flexDirection="column">
                <Typography variant="caption">Plan base: ${tds(fixedTotalNetAmount)}</Typography>
                {hasDiscount && <Typography variant="caption">
                  Dscto suscripción anterior: - ${tds(prevSubDiscountNetAmount)}
                </Typography>}
                <Typography variant="caption">Usuarios extras: ${tds(extraUsersTotalNetAmount)}</Typography>
                <Typography variant="caption">GBs extras: ${tds(extraGbsTotalNetAmount)}</Typography>
                <Typography variant="caption">Total IVA: ${tds(ivaAmount)}</Typography>
              </Box>
            } />
          </Box>
          <Typography variant="body1" className={classes.billingTitle}>
            {ucfl(getMonthName(month))} de {year}
          </Typography>
        </Box>
        <Box mb={1} display="flex" alignItems="baseline">
          <Typography variant="h4">{tds(totalAmount)} CLP</Typography>
          <Box ml={1}>
            <Typography variant="body2" color="textSecondary">
              Con cargo el {dayOneInNextMonth}
            </Typography>
          </Box>
        </Box>
        <Box mb={1} display="flex" flexDirection="column">
          <Box alignSelf="flex-end">
            <Typography variant="caption" color="textSecondary">
              Usuarios activos en el periodo - <RouterLink to="/web/company/usage">Ver detalle</RouterLink>
            </Typography>
          </Box>
          <Paper elevation={0} className={classes.activeUsersProgress}>
            <ActiveUsersProgress usersLimit={currentPlan?.usersLimit} activeUsers={activeUsers} />
          </Paper>
        </Box>
      </Box>
    </Paper>
  );
};

BillingCard.propTypes = {
  paymentPreview: PropTypes.shape({
    fixedTotalNetAmount: PropTypes.number.isRequired,
    extraUsersTotalNetAmount: PropTypes.number.isRequired,
    extraGbsTotalNetAmount: PropTypes.number.isRequired,
    prevSubDiscountNetAmount: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]),
    ivaAmount: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired,
    year: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    activeUsers: PropTypes.number.isRequired,
  }).isRequired,
  currentPlan: PropTypes.shape({
    usersLimit: PropTypes.number.isRequired,
  }).isRequired,
};


export { BillingCard };