import React, { useEffect, useState } from 'react';
import { Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import { SnackbarWrapper } from 'src/components';
import checkers from 'src/utils/checkers';
import { Link } from 'react-router-dom';
import { upperCaseOnlyFirstLetter as ucofl } from 'src/utils/formatters';


const useStyles = makeStyles(theme => ({
  inWeb: {
    marginTop: theme.spacing(10),
    width: '85%',
  },
  inWebAlert: {
    width: '100%',
  },
}));

const lastDayAllowedInUnpaidStatus = 10;

// eslint-disable-next-line
const CompanyAccountMessage = ({ userName, isAdmin, companyName, handleClose, isUnpaidSub, isExpiredSub }) => {
  const currentMonth = ucofl(new Date().toLocaleString('es-ES', { month: 'long' }));
  const currentYear = new Date().getFullYear();
  return (
    <>
      {isUnpaidSub &&
        <>
          Estimad@ {userName} la fecha límite para pagar la suscripción ha terminado, la cuenta de la empresa {companyName} será
          suspendida si es que no se regulariza la situación antes
          del {lastDayAllowedInUnpaidStatus} de {currentMonth} de {currentYear} {isAdmin && <>, puede visualizar los
          planes disponibles en este <Link onClick={handleClose} to="/web/company/subscription/plans">enlace</Link>, si tiene
          cualquier duda escríbanos a contacto@nviro.cl.</>}
        </>
      }
      {isExpiredSub &&
        <>
          Estimad@ {userName} la fecha límite para regularizar el pago de la suscripción ha terminado, la cuenta
          de la empresa {companyName} se encuentra suspendida, debe contratar un plan para volver a tener
          acceso a la cuenta{isAdmin && <>, puede visualizar los planes disponibles en
          este <Link onClick={handleClose} to="/web/company/subscription/plans">enlace</Link>, si tiene cualquier
          duda escríbanos a contacto@nviro.cl</>}.
        </>
      }
    </>
  );
};

// eslint-disable-next-line
const PersonalAccountMessage = ({ userName }) =>
  <>
    Estimado {userName}, como parte de las continuas mejoras de seguridad y funcionalidades de la plataforma de Nviro Capture
    le contamos que ahora es posible suscribirse con el fin de poner a su disposición el máximo potencial de la
    plataforma, puedes revisar <Link to="/web/user/subscription">aquí</Link> los detalles.
  </>;

const SubscriptionStatusAlert = ({ company, user, subscriptionStatus }) => {

  const classes = useStyles();
  const [ snackbarOpen, setSnackbarOpen ] = useState(true);
  const isAdmin = checkers.isAdmin(company.role);
  const isAdminOrCoordinator = isAdmin || checkers.isCoordinator(company.role);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    setSnackbarOpen(true);
  }, [ company.name ]);

  const isPersonal = company.private;
  const userName = [ user.firstName, user.lastName ].join(' ');

  const isUnpaidSub = checkers.isUnpaidSub(subscriptionStatus);
  const isExpiredSub = checkers.isExpiredSub(subscriptionStatus);
  const isExpiredSubAndAdmin = isExpiredSub && isAdmin;
  const isUnpaidSubAndAdminOrCoordinator = isUnpaidSub && isAdminOrCoordinator;

  return (
    <>
      {/* TODO: quitar !isPersonal cuando exista la suscripción en personas */}
      {(isUnpaidSubAndAdminOrCoordinator || isExpiredSubAndAdmin) && !isPersonal &&
        (
          <SnackbarWrapper
            classes={{ root: classes.inWeb }}
            open={snackbarOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transitionDuration={1000}
            TransitionComponent={Fade}
          >
            <Alert classes={{ root: classes.inWebAlert }} onClose={handleClose} variant="filled" severity="error">
              {isPersonal ?
                <PersonalAccountMessage userName={userName} />
                : <CompanyAccountMessage
                  isUnpaidSub={isUnpaidSub}
                  isExpiredSub={isExpiredSub}
                  handleClose={handleClose}
                  userName={userName}
                  isAdmin={isAdmin}
                  companyName={company.name}
                />
              }
            </Alert>
          </SnackbarWrapper>
        )
      }
    </>
  );
};

SubscriptionStatusAlert.propTypes = {
  company: PropTypes.object,
  user: PropTypes.object,
  subscriptionStatus: PropTypes.string,
};


export { SubscriptionStatusAlert };
