import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Box, InputLabel, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import L from 'leaflet';

import { MapWrapper } from 'src/components';
import { getMarkerIcon, santiagoCoords } from 'src/components/map/mapHelper';
import { isEmpty, isNullOrUndefined } from 'src/utils/util';


const createMarker = ({ lat, lng }) => new L.Marker({ lat, lng }, { icon: getMarkerIcon('R', 'nviro-fauna') });

const useStyles = makeStyles(theme => ({
  map: {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  group: {
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray,
    padding: theme.spacing(1),
  },
}));

const CoordinatesInput = ({ id = 'coordinates-input-map', value, onChange, required = false, onlyCoords = false, showAccuracy = true }) => {

  let { lat, lng, altitude, accuracy } = value;
  lat = isNullOrUndefined(lat) ? santiagoCoords.lat : lat;
  lng = isNullOrUndefined(lng) ? santiagoCoords.lng : lng;
  altitude = isNullOrUndefined(altitude) ? '' : altitude;
  accuracy = isEmpty(accuracy) ? 0 : accuracy;

  const classes = useStyles();
  const mapRef = useRef();
  const [ updateDragEventHandler, setUpdateDragCoordsHandler ] = useState(false);
  const marker = useMemo(() => createMarker({ lat, lng }), []); //eslint-disable-line

  const mapOptions = {
    center: [ lat, lng ],
    scrollWheelZoom: false,
    zoom: 10,
    doubleClickZoom: false,
    attributionControl: false,
  };

  const dragCoordsHandler = e => {
    const isDragEvent = e.originalEvent;
    if (isDragEvent) {
      const coords = mapRef.current.getCenter();
      let lngFix = coords.lng;
      if (coords.lng < -180) {
        lngFix = coords.lng + Math.floor(Math.abs(coords.lng - 180) / 360) * 360;
      } else if (coords.lng > 180) {
        lngFix = coords.lng - Math.floor(Math.abs(coords.lng + 180) / 360) * 360;
      }
      const parsedCoords = { lat: parseFloat(coords.lat.toFixed(8), 10), lng: parseFloat(lngFix.toFixed(8), 10) };
      onChange({ ...parsedCoords, altitude, accuracy });
    }
  };

  const dragMarkerHandler = e => {
    const isDragEvent = e.originalEvent;
    if (isDragEvent) {
      const coords = mapRef.current.getCenter();
      const parsedCoords = { lat: parseFloat(coords.lat.toFixed(8), 10), lng: parseFloat(coords.lng.toFixed(8), 10) };
      marker.setLatLng(parsedCoords);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    mapRef.current.on('move', dragCoordsHandler);
    mapRef.current.on('move', dragMarkerHandler);
    return () => mapRef.current.off('move', dragCoordsHandler);
  }, [ updateDragEventHandler ]);
  /* eslint-disable react-hooks/exhaustive-deps */

  const handleChange = e => {
    const newValue = {
      [e.target.name]: e.target.value === '' ? ''
      : e.target.name === 'altitude' ? parseInt(e.target.value, 10)
      : e.target.name === 'accuracy' && e.target.value < 0 ? 0
      : e.target.name === 'lat' ? (
        parseFloat(e.target.value, 10) < -90 ? parseFloat(-90, 10) :
        parseFloat(e.target.value, 10) > 90 ? parseFloat(90, 10) :
        parseFloat(e.target.value, 10)
      )
        : e.target.name === 'lng' ? (
          parseFloat(e.target.value, 10) < -180 ? parseFloat(-180, 10) :
          parseFloat(e.target.value, 10) > 180 ? parseFloat(180, 10) :
          parseFloat(e.target.value, 10)
        )
          : parseFloat(e.target.value, 10),
    };
    const coords = { lat, lng, altitude, accuracy, ...newValue };

    if ([ 'accuracy', 'altitude' ].includes(e.target.name)) {
      onChange(coords);
      setUpdateDragCoordsHandler(prev => !prev);
    }

    if ([ 'lat', 'lng' ].includes(e.target.name) && !Object.values(coords).some(val => isNaN(val))) {
      onChange(coords);
      mapRef.current.eachLayer(layer => {
        if (layer instanceof L.Marker) {
          layer.setLatLng(coords);
        }
      });
      mapRef.current.setView(coords);
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" className={classes.group}>
        <Box display="flex" pt={1}>
          <Box width={`${onlyCoords ? '50' : !showAccuracy ? '40' : '30'}%`} mr={1}>
            <InputLabel required={required} shrink>Latitud</InputLabel>
            <TextField value={isEmpty(lat) ? '' : parseFloat(lat.toFixed(8))} type="number" name="lat" variant="outlined"
              size="small" onChange={handleChange} fullWidth autoComplete="off" inputProps={{ step: 1 / Math.pow(10, 8) }}/>
          </Box>
          <Box width={`${onlyCoords ? '50' : !showAccuracy ? '40' : '30'}%`} mx={1}>
            <InputLabel required={required} shrink>Longitud</InputLabel>
            <TextField value={isEmpty(lng) ? '' : parseFloat(lng.toFixed(8))} type="number" name="lng" variant="outlined"
              size="small" onChange={handleChange} fullWidth autoComplete="off" inputProps={{ step: 1 / Math.pow(10, 8) }} />
          </Box>
          {!onlyCoords &&
            <>
              <Box width="20%" mx={1}>
                <InputLabel shrink>Altitud</InputLabel>
                <TextField value={altitude} type="number" name="altitude" variant="outlined" size="small" onChange={handleChange}
                  fullWidth autoComplete="off" />
              </Box>
              {showAccuracy &&
                <Box width="20%" ml={1}>
                  <InputLabel shrink>Precisión</InputLabel>
                  <TextField value={accuracy} type="number" name="accuracy" variant="outlined" size="small" onChange={handleChange}
                    fullWidth autoComplete="off" inputProps={{ min: 0 }}
                    onKeyPress={event => (event?.key === '-' || event?.key === '+') && event.preventDefault()}
                  />
                </Box>
              }
            </>
          }
        </Box>
        <Box mt={1}>
          <MapWrapper id={id} className={classes.map} options={mapOptions} height={150} layers={[ marker ]} mapRef={mapRef}
            showBaseMaps
          />
        </Box>
      </Box>
    </>
  );
};

CoordinatesInput.propTypes = {
  value: PropTypes.shape({
    lat: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    lng: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    altitude: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    accuracy: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  }),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  onlyCoords: PropTypes.bool,
  showAccuracy: PropTypes.bool,
  id: PropTypes.string,
};


export {
  CoordinatesInput,
};