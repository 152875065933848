import React, { useContext, useEffect, useState } from 'react';
import { Container, Button, Box, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';

import { usersApi } from 'src/services';
import { WebContext } from 'src/scenes/web-context';
import { TitleContainer, ContentContainer, EmailVerificationAlert, DialogWrap } from 'src/components';
import { DeleteAccountDialog } from 'src/scenes/UserSettings/scenes/Profile/components';
import { Alert } from '@material-ui/lab';


const useStyles = makeStyles(theme => ({
  dangerTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dangerText: {
    color: theme.palette.danger.main,
  },
  dangerIcon: {
    marginLeft: '5px',
    color: theme.palette.danger.main,
  },
  deleteButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.danger.main,
  },
}));

const AccountRemoveContainer = () => {

  const classes = useStyles();
  const history = useHistory();
  const { currentUser: { emailVerified } } = useContext(WebContext);

  const [ dialog, setDialog ] = useState({
    isOpen: false,
    data: {},
    type: '',
  });

  useEffect(() => {
    document.title = 'Perfil - Nviro Capture';
  }, []);

  const deleteAccount = async () => {
    await usersApi.deleteAccount();
    history.push(`/`);
    localStorage.clear();
  };

  const openDialog = type => setDialog(pd => ({ ...pd, isOpen: true, type }));

  const closeDialog = () => setDialog(pd => ({ ...pd, isOpen: false, type: '' }));

  const getDialogType = () => {
    const commonActions = { closeDialog, deleteAccount };
    switch (dialog.type) {
      case 'accountDeletion':
        return <DeleteAccountDialog
          actions={{ ...commonActions }}
        />;
      default:
        break;
    }
  };

  const onPressAccountDeletion = () => openDialog('accountDeletion');

  return (
    <>
      <Grid container>
        <TitleContainer>Eliminación de cuenta</TitleContainer>
        <ContentContainer>
          <Container maxWidth="lg">
            <br />
            <Paper className={classes.paper} elevation={0}>
              <Box p={2}>
                <EmailVerificationAlert mt={1} mb={2} px={2}/>
                <Alert severity="info">
                  Al eliminar tu cuenta <strong>no podrás trabajar en ninguna campaña</strong>, así
                  como <strong>tampoco acceder ni sincronizar datos</strong>
                </Alert>
                {emailVerified &&
                    <Grid container>
                      <Grid item md={4} sm={6} xs={12}>
                        <Box mx={4} mt={3} >
                          <Button variant="contained" className={classes.deleteButton} onClick={onPressAccountDeletion}>
                            Eliminar cuenta
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                }
              </Box>
            </Paper>
          </Container>
        </ContentContainer>
        <DialogWrap maxWidth='xs' fullWidth onClose={dialog.closeDialog} open={dialog.isOpen}>
          {getDialogType()}
        </DialogWrap>
      </Grid>
    </>);
};


export {
  AccountRemoveContainer,
};