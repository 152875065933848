import React from 'react';
import { Grid, Paper, Box, Typography, Button, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { LabelChip } from 'src/components';
import { FileSign } from 'src/utils/mdIcons';
import { getDayIgnoreTZ, thousandsDotSeparator as tds } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  plan: {
    textAlign: 'center',
  },
  planName: {
    textTransform: 'uppercase',
  },
  users: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    color: theme.palette.common.gray600,
  },
  price: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  priceExtra: {
    color: theme.palette.common.gray600,
  },
  paper: {
    height: '100%',
  },
  billed: {
    color: theme.palette.common.black,
    fontWeight: '500',
    marginBottom: theme.spacing(1),
  },
  chipCant: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    height: theme.spacing(2),
    fontSize: '0.75rem',
  },
}));

const SubscriptionPlan = props => {

  const { plan, subscription, openChangeSubscriptionDialog, isCurrentPlan, isYearly, isSubCreation, hasUnpaidBillingCycles } = props;
  const { nextSubscription, planId: subPlanId, billingFrequencyId: currentPlanFrequency } = subscription;

  const classes = useStyles();

  const isOnlyAFrequencyIncrease = currentPlanFrequency === 'monthly' && isYearly && isCurrentPlan;
  const isOnlyAFrequencyDecrease = currentPlanFrequency === 'annual' && !isYearly && isCurrentPlan;
  const isCurrentPlanAndFrequency = (
    isCurrentPlan
    && (isYearly && currentPlanFrequency === 'annual' || !isYearly && currentPlanFrequency === 'monthly')
  );

  const hasPreActiveSub = Boolean(nextSubscription);
  const isPreActivePlanAndFrequency = (
    hasPreActiveSub
    && parseInt(nextSubscription.planId, 10) === parseInt(plan.id)
    && (isYearly && nextSubscription.billingFrequencyId === 'annual' || !isYearly && nextSubscription.billingFrequencyId === 'monthly')
  );

  const isPlanUpgrade = parseInt(plan.id, 10) > parseInt(subPlanId, 10);
  const isPlanDowngrade = parseInt(plan.id, 10) < parseInt(subPlanId, 10);

  return (
    <Grid item xs={3} className={classes.plan}>
      <Paper elevation={0} className={classes.paper}>
        <Box height="100%" py={2} display="flex" flexDirection="column">
          <Typography className={classes.planName} color="textPrimary" variant="h5">{plan.name}</Typography>
          <Typography className={classes.users} color="textSecondary" variant="caption">
            Hasta {plan.usersLimit} usuarios activos al mes
          </Typography>
          <Box className={classes.price}>
            <Typography color="textPrimary" variant="h5">
              {tds(Math.floor(isYearly ? plan.amount * 0.85 : plan.amount))} {plan.unitValueId}
              <Typography component="span" variant="body2">/ mes + iva</Typography>
            </Typography>
            <Typography className={classes.billed} variant="caption">
              {isYearly ?
                `${tds(Math.floor(plan.amount * 12 * 0.85))} ${plan.unitValueId} facturado anualmente`
                : 'Facturado mensualmente'
              }
            </Typography>
            <Typography className={classes.priceExtra} color="textSecondary" variant="caption">
              (+ {tds(Math.floor(plan.extraUserAmount))} {plan.unitValueId} por usuario extra al mes)
            </Typography>
            <Typography className={classes.priceExtra} color="textSecondary" variant="caption">
              (+ {tds(Math.floor(plan.extraGbStorageAmount))} {plan.unitValueId} por GB extra al mes)
            </Typography>
          </Box>
          {isCurrentPlanAndFrequency ?
            <Box mt={1}>
              <LabelChip color="primary" variant="outlined" label="Plan actual" />
            </Box>
            : isPreActivePlanAndFrequency ?
              <Box mt={1}>
                <LabelChip color="primary" variant="outlined" label={`Plan a activar el ${getDayIgnoreTZ(nextSubscription.startDate)}`} />
              </Box>
              :
              <Box>
                <Tooltip title={hasUnpaidBillingCycles ? `No es posible cambiar plan a una suscripción con pagos pendientes` : ''}>
                  <span>
                    <Button
                      component="span"
                      size="small"
                      variant="contained"
                      startIcon={<FileSign />}
                      color="primary"
                      onClick={() => openChangeSubscriptionDialog(plan.id)}
                      disabled={hasUnpaidBillingCycles}
                    >
                      {(
                        isSubCreation ? 'Suscribirse'
                        : isOnlyAFrequencyIncrease ? 'Cambiar a anual'
                        : isOnlyAFrequencyDecrease ? 'Cambiar a mensual'
                        : isPlanDowngrade ? 'Disminuir plan'
                        : isPlanUpgrade ? 'Mejorar plan'
                        : 'Cambiar plan'
                      )}
                    </Button>
                  </span>
                </Tooltip>
              </Box>
          }
        </Box>
      </Paper>
    </Grid>
  );
};

SubscriptionPlan.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    usersLimit: PropTypes.number.isRequired,
    amount: PropTypes.string.isRequired,
    unitValueId: PropTypes.string.isRequired,
    extraUserAmount: PropTypes.string.isRequired,
    extraGbStorageAmount: PropTypes.string.isRequired,
  }).isRequired,
  subscription: PropTypes.shape({
    planId: PropTypes.string,
    billingFrequencyId: PropTypes.string,
    endDate: PropTypes.string,
    nextSubscription: PropTypes.shape({
      planId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
      billingFrequencyId: PropTypes.string,
      startDate: PropTypes.string,
    }),
  }),
  isCurrentPlan: PropTypes.bool.isRequired,
  openChangeSubscriptionDialog: PropTypes.func.isRequired,
  isYearly: PropTypes.bool.isRequired,
  isSubCreation: PropTypes.bool.isRequired,
  hasUnpaidBillingCycles: PropTypes.bool.isRequired,
};


export { SubscriptionPlan };