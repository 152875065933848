import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { TitleContainer, ContentContainer, Cards } from 'src/components';
import { payApi } from 'src/services';
import { GetApp } from '@material-ui/icons';
import { PaymentCard } from 'src/scenes/CompanySettings/scenes/PaymentHistory/components';

const useStyles = makeStyles(theme => ({
  defaultItem: {
    padding: theme.spacing(1),
  },
}));

const PaymentHistoryContainer = () => {
  const classes = useStyles();
  const [ paymentHistory, setPaymentHistory ] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const paymentHistory = await payApi.getPaymentHistory();
      setPaymentHistory(paymentHistory.map((p, index) => ({ ...p, name: `Factura ${index + 1}` })));
    };
    fetchData();
  }, []);

  useEffect(() => document.title = 'Historial de pagos - Nviro Capture', []);

  const cardActions = itemData => ([
    {
      Icon: GetApp,
      tooltip: 'Descargar factura',
      onClick: () => downloadInvoice(itemData.id),
    },
  ]);

  const downloadInvoice = async paymentId => {
    await payApi.downloadPayment(paymentId);
  };

  return (
    <Grid container>
      <TitleContainer breadcrumbs={[ 'company', { name: 'Historial de pagos' } ]}>Historial de pagos</TitleContainer>
      <ContentContainer>
        <Box p={5}>
          {paymentHistory.length > 0 ?
            <Cards
              values={paymentHistory}
              actions={cardActions}
              Content={({ itemData }) => <PaymentCard {...itemData} />}
              classes={{ defaultItem: classes.defaultItem }}
              alwaysShowActions
            />
            :
            <Typography variant="body1" color="textPrimary">
              Aún no existen pagos asociados, podrás descargar la factura en esta sección cuando se haga
              un cargo a tu medio de pago ingresado para realizar el pago de la suscripción.
            </Typography>
          }
        </Box>
      </ContentContainer>
    </Grid>
  );
};


export { PaymentHistoryContainer };