import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-easybutton';
import PropTypes from 'prop-types';
import { renderToString } from 'react-dom/server';
import { Delete, Help } from '@material-ui/icons';
import * as togeojson from '@tmcw/togeojson';
import JsZip from 'jszip';

import { LayersPlus, MapMarkerPlus } from 'src/utils/mdIcons';
import { objectIsEmpty, tryParseXml } from 'src/utils/util';
import { getMarkerIcon, getMarkersInsidePolygon, setTextToButtonsInPanel,
  addPixiLayerOnLeafletLayer, getBoundsFromPixiMarkers, getPointNamesToAvoid } from 'src/components/map/mapHelper';
import config from 'src/config/local';


const { satelliteStreetsBaseMap, outdoorsBaseMap, maxSizeFileUpload, maxKmlPoints, maxKmlLines, maxKmlPolygons } = config;
const pointsImportElementId = 'points-import';
const areaImportElementId = 'area-import';

const baseMapOptions = {
  maxZoom: 22,
  maxNativeZoom: 22,
};

class PlanningMap extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      needFitBounds: true,
      finishedLoadingPixi: false,
      loadingPointObjects: false,
      finishedLoadingPointObjects: false,
    };
    this.currentOwnList = [];
  }

  addPoints = async prevPointsList => {
    const prevIds = prevPointsList.map(point => point.id);
    const currentPointsList = this.props.pointsList;
    const pointsToAdd = currentPointsList
      .filter(point => !prevIds.includes(point.id))
      .map(({ id, hash, name, color, code, statusId, plannedLatitude, plannedLongitude, suggestedMethodologies }) => ({
        id, hash, name, color, code, isSelected: false, statusId, suggestedMethodologies,
        coords: { lat: plannedLatitude, lng: plannedLongitude },
      }));
    this.pixiContainer.metaData.createMarkers(pointsToAdd);
  };

  removePoints = (prevPointsList, selectedPoints) => {
    this.pixiContainer.metaData.removeMarkers(selectedPoints);
  };

  // probablemente sería bueno refactorizar el flujo para que PlanningCampaignContainer comunique si se actualizaron
  // las metodologías o si se asignó, para no tener que hacer ifs acá para saber qué cambio
  updatePoints = () => {
    if (this.props.selectedPoints.length) {
      const selectedIds = this.props.selectedPoints.map(point => point.metaData.id);
      // TODO: cambiar "suggestedMethodologies" por algo como "highlightColor" y ponerlo en getPointsWithColor del container, o algo así.
      // Para que esta parte que es más solo de lo visual no esté tan ligada a lo de las metodologías
      const pointsDict = this.props.pointsList.reduce((acc, curr) => {
        acc[curr.id] = { color: curr.color, suggestedMethodologies: curr.suggestedMethodologies };
        return acc;
      }, {});

      let newColor;
      let newSuggestions;
      // ver si se sugirieron metodologías o si se asignó persona:
      for (let ind = 0; ind < selectedIds.length; ind++) {
        const currSelected = this.props.selectedPoints[ind].metaData;
        const newCurrSelected = pointsDict[currSelected.id];
        if (currSelected.color !== newCurrSelected.color) {
          newColor = newCurrSelected.color;
          break;
        } else if (currSelected.suggestedMethodologies !== newCurrSelected.suggestedMethodologies) {
          newSuggestions = newCurrSelected.suggestedMethodologies;
        }
      }

      if (newColor) {
        this.pixiContainer.metaData.setMarkersColor(this.props.selectedPoints, newColor);
      } else if (newSuggestions) {
        const gotMethodologies = newSuggestions?.length;
        const lostMethodologies = newSuggestions?.length === 0;
        if (gotMethodologies) {
          this.pixiContainer.metaData.highlightMarkerText(
            this.props.selectedPoints,
            { suggestedMethodologies: newSuggestions, isSelected: false },
          );
        } else if (lostMethodologies) {
          this.pixiContainer.metaData.unhighlightMarkerText(
            this.props.selectedPoints,
            { suggestedMethodologies: newSuggestions, isSelected: false },
          );
        }
      }
    }
  };

  fitMapBounds = () => {
    const kmlBounds = this.kmlLayers.getBounds();
    const markers = this.pixiContainer.children.filter(c => c.constructor.name === 'Sprite');
    const pointsBounds = markers.length ? getBoundsFromPixiMarkers(markers) : new L.latLngBounds();
    const allBounds = kmlBounds.extend(pointsBounds);
    !objectIsEmpty(allBounds) && this.map.fitBounds(allBounds);
    this.setState({ needFitBounds: false });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.state.finishedLoadingPixi) {
      return;
    }

    const isInterestAreasUpdating = this.props.interestArea.length !== prevProps.interestArea.length;
    const needsFirstLoad = this.state.finishedLoadingPixi && !prevState.finishedLoadingPixi;
    if (isInterestAreasUpdating || needsFirstLoad) {
      this.loadKml();
      this.addLegend();
    }

    if (this.props.csLang?.pointPrefix !== 'U' && !this.state.finishedLoadingPointObjects && !this.state.loadingPointObjects) {
      // cargar cosas de unidades de muestreo
      this.setState({ loadingPointObjects: true });
      return this.loadPointMapObjects();
    }

    if (this.props.pointsList.length > this.currentOwnList.length) {
      this.addPoints(this.currentOwnList);
      this.addLegend();
    } else if (this.props.pointsList.length < this.currentOwnList.length) {
      this.removePoints(this.currentOwnList, prevProps.selectedPoints);
      this.addLegend();
    } else if (this.props.pointsList !== this.currentOwnList) {
      this.updatePoints();
      this.addLegend();
    }

    if (this.props.isPointsListFetched && this.state.needFitBounds) {
      this.fitMapBounds();
    }

    if (this.state.finishedLoadingPointObjects) {
      this.drawControl.setDrawingOptions({ marker: { icon: getMarkerIcon(this.getNameAndNumber().name) } });
    }
    this.currentOwnList = this.props.pointsList;
  };

  async loadInitialMapObjects() {
    try {
      const baseMaps = {
        'Híbrido': L.tileLayer(satelliteStreetsBaseMap, baseMapOptions),
        'Topográfico': L.tileLayer(outdoorsBaseMap, baseMapOptions),
      };

      this.map = L.map('planification-map', {
        center: [ -33.4679997, -70.7003513 ],
        zoom: 11,
        layers: [
          baseMaps['Híbrido'],
        ],
      });

      L.control.layers(baseMaps).addTo(this.map).setPosition('topleft');

      const { addPointsToSelectedPoints, deletePointsFromSelectedPoints } = this.props.actions;
      const actions = { addPointsToSelectedPoints, deletePointsFromSelectedPoints };

      await addPixiLayerOnLeafletLayer({ leafletLayer: this.map, actions });
      this.map.eachLayer(layer => {
        if (layer._pixiContainer) {
          this.pixiContainer = layer._pixiContainer;
        }
      });


      //KML import things
      this.kmlLayers = new L.FeatureGroup();
      this.kmlLayers.addTo(this.map);
      this.map.createPane('interestAreaPane').style.zIndex = 200;
      const iconStyle = { color: '#464646' };

      const helpButton = L.easyButton({
        id: 'help-btn',
        position: 'topleft',
        type: 'replace',
        states: [ {
          onClick: () => this.props.actions.toggleDialog({ contentType: 'help' }),
          title: 'Ayuda',
          icon: renderToString(<Help fontSize="small" style={iconStyle} />),
        } ],
      });
      helpButton.addTo(this.map);

      const kmlAreaInput = document.getElementById(areaImportElementId);
      const kmlImportAreaToolButton = L.easyButton({
        id: 'import-area',
        position: 'topleft',
        type: 'replace',
        states: [ {
          onClick: () => kmlAreaInput.click() && false,
          title: 'Importar área de interés (KML/KMZ)',
          icon: renderToString(<LayersPlus fontSize="small" style={iconStyle} />),
        } ],
      });
      kmlImportAreaToolButton.addTo(this.map);

      this.setState({ finishedLoadingPixi: true });
    } catch (e) {
      console.error(e);
    }
  }

  loadPointMapObjects() {
    try {
      const { csLang } = this.props;
      setTextToButtonsInPanel(L, csLang);

      this.drawControl = new L.Control.Draw({
        position: 'topleft',
        draw: {
          rectangle: false,
          circlemarker: false,
          polyline: false,
          polygon: true,
          circle: false,
          marker: {
            repeatMode: true,
          },
        },
      });
      this.map.addControl(this.drawControl);

      //Maps events
      this.map.on(L.Draw.Event.CREATED, async e => {
        const newLayer = e.layer;
        if (e.layerType === 'marker') {
          const nextMarkerName = await this.createMarker(newLayer._latlng);
          this.drawControl.setDrawingOptions({ marker: { icon: getMarkerIcon(nextMarkerName) } });
        } else if (e.layerType === 'polygon') {
          const polygon = newLayer;
          const currentMarkers = this.pixiContainer.children.filter(c => c.metaData && c.isSprite);
          const markersInsidePolygon = getMarkersInsidePolygon(currentMarkers, polygon);
          const [ selectedMarkers, deselectedMarkers ] = markersInsidePolygon.reduce((markers, marker) => {
            markers[marker.metaData.isSelected ? 0 : 1].push(marker);
            return markers;
          }, [ [], [] ]);
          this.pixiContainer.metaData.selectMarkers(deselectedMarkers);
          this.pixiContainer.metaData.deselectMarkers(selectedMarkers);
        }
      });

      const kmlPointsInput = document.getElementById(pointsImportElementId);
      const kmlImportPointsToolButton = L.easyButton({
        id: 'import-points-btn',
        position: 'topleft',
        type: 'replace',
        states: [ {
          onClick: () => kmlPointsInput.click() && false,
          title: `Importar ${csLang.points} (KML/KMZ)`,
          icon: renderToString(<MapMarkerPlus fontSize="small" style={{ color: '#464646' }} />),
        } ],
      });
      kmlImportPointsToolButton.addTo(this.map);
      this.setState({ finishedLoadingPointObjects: true, loadingPointObjects: false });
    } catch (e) {
      console.error(e);
    }
  }

  componentDidMount() {
    this.loadInitialMapObjects();
  }

  makeSuggestedMethodologiesLegend = ({ pointIds, currentMarkers, legendContainer, labelText, insideColor }) => {
    const markersToToggle = currentMarkers.filter(marker => pointIds.includes(marker.metaData.id));
    const areMarkersNoMethsShowing = markersToToggle.length === 0 || markersToToggle[0]?.visible;
    const noMethsItems = L.DomUtil.create('div', 'sug-met-legend-element', legendContainer);
    const checkbox = L.DomUtil.create(
      'div', `sug-met-legend-box ${areMarkersNoMethsShowing ? '' : 'sug-met-legend-box-uncheck'}`, noMethsItems,
    );
    checkbox.innerHTML = 'E';
    checkbox.style.color = insideColor;
    const noMethsLabel = L.DomUtil.create('div', '', noMethsItems);
    noMethsLabel.innerHTML = `<span class="bcw-legend-label">${labelText} (${pointIds.length})</span>`;

    L.DomEvent.on(checkbox, 'click', e => {
      const colorCircle = e.target;
      if (L.DomUtil.hasClass(colorCircle, 'sug-met-legend-box-uncheck')) {
        L.DomUtil.removeClass(colorCircle, 'sug-met-legend-box-uncheck');
        this.pixiContainer.metaData.toggleMarkersVisibility(markersToToggle, true);
      } else {
        L.DomUtil.addClass(colorCircle, 'sug-met-legend-box-uncheck');
        this.pixiContainer.metaData.toggleMarkersVisibility(markersToToggle, false);
      }
    });
  };

  addLegend = () => {
    const { csLang } = this.props;
    const currentMarkers = this.pixiContainer?.children.filter(c => c.constructor.name === 'Sprite');
    const userColorMapValues = Object.values(this.props.userColorMap).filter(f => f.points && f.points.length > 0);

    const hiddenLayers = [];
    this.kmlLayers?.eachLayer(layer => {
      const element = document.getElementById(`layer-${layer.options.id}`);
      if (element) {
        const isLayerHidden = L.DomUtil.hasClass(element, 'bcw-legend-box-uncheck');
        isLayerHidden && hiddenLayers.push(layer.options.id);
      }
    });

    this.legend && this.legend.remove();
    this.legend = L.control({ position: 'bottomleft' });
    this.legend.onAdd = () => {
      const legendContainer = L.DomUtil.create('div', 'info bcw-legend');

      if (userColorMapValues.length) {
        const pointsTitle = L.DomUtil.create('div', '', legendContainer);
        pointsTitle.innerHTML = `<strong>${csLang.Points}</strong>`;
        userColorMapValues.forEach(v => {

          const pointsIds = v.points.map(p => p.id);
          const markersToToggle = currentMarkers.filter(marker => pointsIds.includes(marker.metaData.id));
          const areMarkersShowing = markersToToggle[0]?.visible;

          const item = L.DomUtil.create('div', 'bcw-legend-element', legendContainer);
          const checkbox = L.DomUtil.create('div', `bcw-legend-box ${areMarkersShowing ? '' : 'bcw-legend-box-uncheck'}`, item);
          checkbox.style.backgroundColor = v.color;
          checkbox.style.borderColor = v.color;

          const userLabel = L.DomUtil.create('div', '', item);
          userLabel.innerHTML = `<span class="bcw-legend-label">${v.name} (${v.points.length})</span>`;

          L.DomEvent.on(checkbox, 'click', e => {
            const colorCircle = e.target;
            if (L.DomUtil.hasClass(colorCircle, 'bcw-legend-box-uncheck')) {
              L.DomUtil.removeClass(colorCircle, 'bcw-legend-box-uncheck');
              this.pixiContainer.metaData.toggleMarkersVisibility(markersToToggle, true);
            } else {
              L.DomUtil.addClass(colorCircle, 'bcw-legend-box-uncheck');
              this.pixiContainer.metaData.toggleMarkersVisibility(markersToToggle, false);
            }
          });
        });

        // Espera que en fauna suggestedMethodologies sea una lista vacía (no nulo) cuando no hay metodologías sugeridas
        if (this.props.pointsList?.[0]?.suggestedMethodologies) {
          const pointsWithMeths = this.props.pointsList.filter(stat => stat.suggestedMethodologies?.length).map(p => p.id);
          this.makeSuggestedMethodologiesLegend({
            pointIds: pointsWithMeths, currentMarkers, legendContainer, labelText: 'Con metodologías sugeridas', insideColor: '#DB6E00',
          });

          const pointsNoMethsIds = this.props.pointsList.filter(stat => !stat.suggestedMethodologies?.length).map(p => p.id);
          this.makeSuggestedMethodologiesLegend({
            pointIds: pointsNoMethsIds, currentMarkers, legendContainer, labelText: 'Sin metodologías sugeridas', insideColor: 'black',
          });
        }
      }
      if (this.kmlLayers?.getLayers().length) {
        const kmlTitle = L.DomUtil.create('div', '', legendContainer);
        kmlTitle.innerHTML = `<strong>Área de interés</strong>`;

        this.kmlLayers.eachLayer(layer => {
          const areLayerShowing = !hiddenLayers.includes(layer.options.id);
          if (!areLayerShowing) {
            this.map.removeLayer(layer);
          }
          const item = L.DomUtil.create('div', 'bcw-legend-element', legendContainer);
          const checkbox = L.DomUtil.create('div', `bcw-legend-box ${areLayerShowing ? '' : 'bcw-legend-box-uncheck'}`, item);
          checkbox.style.backgroundColor = 'blue';
          checkbox.style.borderColor = 'blue';
          checkbox.id = `layer-${layer.options.id}`;
          const layerTitle = L.DomUtil.create('div', '', item);
          layerTitle.innerHTML = `<span class="bcw-legend-title">${layer.options.name}</span>`;
          const delBtn = L.DomUtil.create('div', 'bcw-legend-del-kml', item);
          delBtn.innerHTML = renderToString(<Delete fontSize="small" style={{ fontSize: '1rem', marginLeft: '2px' }} />);

          L.DomEvent.on(checkbox, 'click', e => {
            const colorCircle = e.target;
            if (L.DomUtil.hasClass(colorCircle, 'bcw-legend-box-uncheck')) {
              L.DomUtil.removeClass(colorCircle, 'bcw-legend-box-uncheck');
              this.map.addLayer(layer);
            } else {
              L.DomUtil.addClass(colorCircle, 'bcw-legend-box-uncheck');
              this.map.removeLayer(layer);
            }
          });

          L.DomEvent.on(delBtn, 'click', async e => {
            e.stopPropagation();
            const colorBtn = e.target;
            L.DomUtil.addClass(colorBtn, 'bcw-legend-box-uncheck');
            const loading = L.DomUtil.create('div');
            loading.innerHTML = renderToString(<Box ml={0.5}><CircularProgress size={12} thickness={4.8} /></Box>);
            delBtn.replaceWith(loading);
            this.kmlLayers.removeLayer(layer);
            await this.props.actions.deleteKml(layer.options.id);
            item.parentNode.removeChild(item);
          });
        });
      }
      return legendContainer;
    };
    this.legend.addTo(this.map);
  };

  createMarker = async latLng => {
    const { createPoint } = this.props.actions;
    const { name, number } = this.getNameAndNumber();
    await createPoint({ name, plannedLatitude: latLng.lat, plannedLongitude: latLng.lng });
    const nextMarkerName = this.getNameAndNumber(number + 1).name;
    return nextMarkerName;
  };

  handlePointsImport = e => this.handleKmlImport(e, 'points-import');

  handleAreaImport = e => this.handleKmlImport(e, 'area-import');

  // TODO: tirarlo a un helper o utils para el manejo de importación de kmls,
  // ya que se usa en varios lados (aca, en el CampaignCreateCensusFourthStep y SmaMapCreateDialog)
  handleKmlImport = async (e, importType) => {
    const file = e.target.files[0];
    let kmlFile;
    const propertyToCheck = file.type || file.name;

    try {
      if (file.size > maxSizeFileUpload) {
        this.props.actions.handleBadImport(
          `El tamaño del KML cargado supera el máximo permitido (${maxSizeFileUpload / 1000000} MB)`,
        );
        return;
      }
      if (propertyToCheck.match(/\.kml/)) {
        kmlFile = file;
      } else if (propertyToCheck.match(/\.kmz/)) {
        const kmzZip = await new JsZip().loadAsync(file);
        const kmlZip = kmzZip.filter(relativePath => relativePath.match(/\.kml$/))[0];
        kmlFile = await kmlZip.async('blob');
      } else {
        this.props.actions.handleBadImport('Solo se aceptan archivos .kml o .kmz');
        return;
      }
      const kmlAsText = await new Response(kmlFile).text();
      const kmlDoc = tryParseXml(kmlAsText);
      if (!kmlDoc) {
        console.error('Error recibiendo archivo. El KML es inválido.');
        this.props.actions.handleBadImport();
        return;
      }

      const geojson = togeojson.kml(kmlDoc);
      // las geometrías pueden ser una geometría como se debe o null
      if (!geojson?.features?.length || geojson.features.some(feat => feat.geometry === undefined)) {
        console.error('KML parece no contener geometrías');
        this.props.actions.handleBadImport();
        return;
      }

      let data;
      const featuresWithLocation = geojson.features.filter(feat => feat.geometry !== null);
      const dataByGeometry = featuresWithLocation.reduce((acc, obj) => {
        const key = obj.geometry.type;
        acc[key] = acc[key] + 1 || 1;
        return acc;
      }, {});
      if (importType === 'points-import') {
        const importPointsCount = dataByGeometry['Point'];
        if (!importPointsCount) {
          this.props.actions.handleBadImport(
            `El KML recibido no contiene puntos para transformar en ${this.props.csLang.points}`,
          );
          return;
        }
        if (importPointsCount > maxKmlPoints) {
          this.props.actions.handleBadImport(
            `El KML recibido supera el máximo de puntos permitidos (${importPointsCount} recibidos, ${maxKmlPoints} permitidos)`,
          );
          return;
        }

        data = {
          importPointsCount,
          propertiesKeys: Object.keys(featuresWithLocation[0].properties),
          kmlFile,
          fileName: file.name,
          clearMap: () => {
            this.map.eachLayer(layer => {
              if (layer._pixiContainer) {
                layer._pixiContainer.removeChildren();
                const container = layer.utils.getContainer();
                const renderer = layer.utils.getRenderer();
                renderer.render(container);
              }
            });
          },
          doFitBounds: () => this.setState({ needFitBounds: true }),
        };
      } else if (importType === 'area-import') {

        if (!Object.keys(dataByGeometry).some(key => [ 'Point', 'LineString', 'Polygon' ].includes(key))) {
          this.props.actions.handleBadImport(
            `Solo se soportan geometrías de tipo punto, linea y/o polígono dentro del KML`,
          );
          return;
        }

        const importPointsCount = dataByGeometry['Point'];
        const importLinesCount = dataByGeometry['LineString'];
        const importPolygonsCount = dataByGeometry['Polygon'];
        if (importPointsCount && importPointsCount > maxKmlPoints) {
          this.props.actions.handleBadImport(
            `El KML recibido supera el máximo de puntos permitidos (${importPointsCount} recibidos, ${maxKmlPoints} permitidos)`,
          );
          return;
        }
        if (importLinesCount && importLinesCount > maxKmlLines) {
          this.props.actions.handleBadImport(
            `El KML recibido supera el máximo de líneas permitidas (${importLinesCount} recibidas, ${maxKmlLines} permitidas)`,
          );
          return;
        }
        if (importPolygonsCount && importPolygonsCount > maxKmlPolygons) {
          this.props.actions.handleBadImport(
            `El KML recibido supera el máximo de polígonos permitidos (${importPolygonsCount} recibidos, ${maxKmlPolygons} permitidos)`,
          );
          return;
        }
        data = {
          kmlFile,
          fileName: file.name,
          dataByGeometry,
          doFitBounds: () => this.setState({ needFitBounds: true }),
        };
      }
      this.props.actions.toggleDialog({ data, contentType: importType });
    } catch (e) {
      console.error(e);
      this.props.actions.handleBadImport('Ocurrió un error inesperado, por favor intenta más tarde');
    }
  };

  loadKml = () => {
    const { interestArea } = this.props;
    if (interestArea.length) {
      this.kmlLayers.clearLayers();
      interestArea.map(layer => L.geoJSON(layer.geojson, {
        id: layer.id,
        name: layer.name,
        pane: 'interestAreaPane',
        pointToLayer: (feature, latlng) => new L.CircleMarker(latlng, { pane: 'interestAreaPane', radius: 5, fillOpacity: 1 }),
      }).addTo(this.kmlLayers));
    }
  };

  cleanFilesInputs = () => {
    const pointsImportElement = document.getElementById(pointsImportElementId);
    if (pointsImportElement) {
      pointsImportElement.value = '';
    }
    const areaImportElement = document.getElementById(areaImportElementId);
    if (areaImportElement) {
      areaImportElement.value = '';
    }
  };

  getNameAndNumber = (number = 1) => {
    const { pointPrefix } = this.props.csLang;
    const namesToAvoid = getPointNamesToAvoid({ pointsNames: this.props.pointsList.map(p => p.name), pointPrefix });
    while (namesToAvoid.includes(`${pointPrefix}${number}`)) {
      number++;
    }
    const name = `${pointPrefix}${number}`;
    return { number, name };
  };

  render() {
    if (!this.props.isDialogOpen) {
      this.cleanFilesInputs();
    }

    return <>
      <div id="planification-map" style={{ marginTop: '8px', width: '100%', height: '85vh', padding: 0 }}></div>
      <input id={pointsImportElementId} type="file" accept=".kml, .kmz" onChange={this.handlePointsImport} style={{ display: 'none' }} />
      <input id={areaImportElementId} type="file" accept=".kml, .kmz" onChange={this.handleAreaImport} style={{ display: 'none' }} />
    </>;
  }
}

PlanningMap.propTypes = {
  actions: PropTypes.object,
  pointsList: PropTypes.array,
  selectedPoints: PropTypes.array,
  userColorMap: PropTypes.object,
  interestArea: PropTypes.array,
  isPointsListFetched: PropTypes.bool,
  isDialogOpen: PropTypes.bool,
  csLang: PropTypes.object,
};


export { PlanningMap };