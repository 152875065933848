import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography, Divider,
  Link, FormHelperText, Tooltip, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { AlertWrapper, DialogButton, SelectChip } from 'src/components';
import { useFetchStatus } from 'src/hooks';
import { CheckIcon } from 'src/utils/mdIcons';
import { Payment } from '@material-ui/icons';
import { pricing } from 'src/scenes/Home/scenes/Pricing/pricingData';
import { thousandsDotSeparator as tds } from 'src/utils/util';
import { upperCaseOnlyFirstLetter as ucofl } from 'src/utils/formatters';
import { truncateWithEllipsis as twe, getDayIgnoreTZ } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  group: {
    border: `1px solid ${theme.palette.common.gray400}`,
    borderRadius: '6px',
  },
  title: {
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  description: {
    color: theme.palette.common.gray600,
  },
  price: {
    fontWeight: 500,
    fontSize: '1.75rem',
  },
  link: {
    cursor: 'pointer',
  },
  fontWeight500: {
    fontWeight: 500,
  },
  totalPay: {
    borderTop: `1px solid ${theme.palette.common.gray400}`,
    color: theme.palette.common.white,
  },
  totalAmountText: {
    color: theme.palette.common.gray100,
  },
  backgroundEnphasis: {
    backgroundColor: theme.palette.common.gray800,
  },
  cancelBtn: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
}));

const IVA = 0.19;

const _getCurrentSubAmountDiscount = ({ isPlanUpgrade, currentSubscription, selectedFrequency, subStart }) => {
  if (!currentSubscription) {
    return 0;
  }
  const isStartingNow = subStart === 'now';
  const isAnnualToAnnual = currentSubscription.billingFrequencyId === 'annual' && selectedFrequency === 'annual';
  const prevSubLastPaidCycle = (currentSubscription.currentBillingCycle - 1) % 12;
  const cyclesDiscount = prevSubLastPaidCycle === 0 ? 0 : (12 - prevSubLastPaidCycle);
  const discountToShow = (parseFloat(currentSubscription.planAmount) * 0.85) * cyclesDiscount * (1 + IVA);
  const hasPrevPaymentDiscount = isPlanUpgrade && isAnnualToAnnual && cyclesDiscount > 0 && isStartingNow;
  const discountAmount = hasPrevPaymentDiscount ? discountToShow : 0;
  return { discountAmount, discountToShow };
};

const _getPayDay = ({ currentSubscription, isPlanDowngrade, isPlanUpgrade, selectedFrequency, subStart }) => {
  const now = new Date();
  const nowYear = now.getFullYear();
  const nextMonth = now.getMonth() + 1;
  const currentSubStartDate = new Date(currentSubscription.startDate);
  const currentSubYear = currentSubStartDate.getFullYear();
  const currentSubNextMonth = currentSubStartDate.getMonth() + 1;

  const {
    isTrial: isCurrentTrial,
    statusId: currentStatusId,
    billingFrequencyId: currentBillingFrequencyId,
  } = currentSubscription;

  const toAnnual = selectedFrequency === 'annual';
  const toMonthly = selectedFrequency === 'monthly';

  const toAnnualStartingAfter = toAnnual && subStart === 'after';

  const isCurrentActive = currentStatusId === 'active';

  const isChangingToAnnual = currentBillingFrequencyId === 'monthly' && toAnnual && isCurrentActive;
  const isChangingToMonthly = currentBillingFrequencyId === 'annual' && toMonthly && isCurrentActive;

  const isAnActiveTrial = isCurrentTrial && isCurrentActive;

  let payDate;
  if (isAnActiveTrial) {
    payDate = new Date(currentSubYear, currentSubNextMonth + 2, 1);
  } else if (isPlanDowngrade && ((toMonthly && !isChangingToMonthly) || isChangingToAnnual)) {
    payDate = new Date(nowYear, nextMonth + 1, 1);
  } else if ((toAnnual && (isPlanDowngrade || (isPlanUpgrade && toAnnualStartingAfter))) || isChangingToMonthly) {
    payDate = new Date(currentSubYear + 1, currentSubNextMonth, 1);
  } else {
    payDate = new Date(nowYear, nextMonth, 1);
  }

  return payDate;

};

const SubscriptionCreateOrUpdateDialog = props => {
  const { selectedPlan, currentSubscription, creditCard, billingData, isSubCreation, actions, isYearlySelected } = props;
  const classes = useStyles();

  const isCurrentPlan = currentSubscription?.planId === selectedPlan.id;

  const isCurrentSubAnnual = currentSubscription.billingFrequencyId === 'annual';
  const isOnlyAFrequencyIncrease = !isCurrentSubAnnual && isCurrentPlan;
  const isOnlyAFrequencyDecrease = isCurrentSubAnnual && isCurrentPlan;

  const currentSelectedFrequency = (isCurrentSubAnnual && !isOnlyAFrequencyDecrease) ? 'annual' : isYearlySelected ? 'annual' : 'monthly';
  const [ selectedFrequency, setSelectedFrequency ] = useState(currentSelectedFrequency);

  const isPlanUpgrade = (parseInt(selectedPlan.id, 10) > parseInt(currentSubscription.planId, 10)) && !isSubCreation;
  const isPlanDowngrade = (parseInt(selectedPlan.id, 10) < parseInt(currentSubscription.planId, 10)) && !isSubCreation;

  const [ subStart, setSubStart ] = useState((isPlanUpgrade && isCurrentSubAnnual) ? 'now' : null);

  const { fetchStatus, errorMessage, handleConfirm, hasError, hasBadData } = useFetchStatus({
    confirmFn: () => actions.subscribePlan({ selectedFrequency, subStart }),
    validationFn: () => ({ isValid: hasBillingData && hasCreditCard }),
  });

  const hasCreditCard = Boolean(creditCard?.lastDigits);
  const hasBillingData = Boolean(billingData?.id);

  const currentPricing = pricing[selectedPlan.id];
  const isYearly = selectedFrequency === 'annual';

  const economicActivityLabel = hasBillingData ?
    [ billingData.economicActivityCode, ucofl(billingData.economicActivityName) ].join(' - ') : '';

  const amount = Math.floor(isYearly ? (selectedPlan.amount * 0.85 * 12) : selectedPlan.amount);

  const handleSelectFrequency = option => {
    if (option.value === 'monthly') {
      setSubStart('after');
    }
    setSelectedFrequency(option.value);
  };

  const payDay = _getPayDay({ currentSubscription, isPlanDowngrade, isPlanUpgrade, selectedFrequency, subStart });

  const { discountAmount, discountToShow } = _getCurrentSubAmountDiscount({
    isPlanUpgrade,
    currentSubscription,
    selectedFrequency,
    subStart,
  });
  const totalAmount = Math.floor(amount * (1 + IVA)) - discountAmount;

  const payDayStartingAfter = _getPayDay({ currentSubscription, isPlanDowngrade, isPlanUpgrade, selectedFrequency, subStart: 'after' });

  return (
    <>
      <DialogTitle id="form-dialog-title">
        {isSubCreation && <Typography component="span" variant="h4">Confirmación de suscripción</Typography>}
        {isPlanUpgrade && <Typography component="span" variant="h4">Mejorar suscripción a</Typography>}
        {isPlanDowngrade && <Typography component="span" variant="h4">Disminuir suscripción a</Typography>}
        {isOnlyAFrequencyIncrease && <Typography component="span" variant="h4">Cambiar a suscripción anual</Typography>}
      </DialogTitle>
      <DialogContent>
        <Box py={2} px={6}>
          <Box className={classes.group} p={3}>
            <Box display="flex">
              <Box>
                <Typography color="textPrimary" variant="h6" className={classes.title}>Plan {selectedPlan.name}</Typography>
                <Box>
                  <Typography color="textSecondary" variant="body2" className={classes.description}>
                    {selectedPlan.description}
                  </Typography>
                </Box>
              </Box>
              <Box flexGrow={1} />
              <Box textAlign="end">
                <Typography className={classes.price} variant="h4">
                  {tds(amount)} {selectedPlan.unitValueId}
                  <Typography component="span" variant="h6">+ {tds(amount * 0.19)} {selectedPlan.unitValueId} (IVA)</Typography>
                  <Typography component="span" variant="body2"> / {isYearly ? 'año' : 'mes'}</Typography>
                </Typography>
                <Typography className={classes.description} variant="body2">
                  + {tds(Math.floor(selectedPlan.extraUserAmount))} {selectedPlan.unitValueId} por usuario extra al mes |
                  + {tds(Math.floor(selectedPlan.extraGbStorageAmount))} {selectedPlan.unitValueId} por GB extra al mes
                </Typography>
              </Box>
            </Box>
            {currentPricing && <Box py={2}><Divider /></Box>}
            {currentPricing?.features.data.map((feat, index) =>
              <Box key={index} display="flex" py={1} width="100%" alignItems="center">
                <Box pr={2} display="flex" alignItems="center">
                  <CheckIcon fontSize="small" color="primary" />
                </Box>
                <Box flexGrow={1}>
                  <Typography color="textPrimary" variant="body2">{feat}</Typography>
                </Box>
              </Box>,
            )}
          </Box>
          <Box mt={2}>
            <Typography variant="h6">Frecuencia de pago</Typography>
          </Box>
          <Box className={classes.group} p={3}>
            <SelectChip
              value={selectedFrequency}
              options={[
                ...((isOnlyAFrequencyIncrease) ? [] : [ { label: 'Mensual', value: 'monthly' } ]),
                ...((isOnlyAFrequencyDecrease) ? [] : [ { label: 'Anual', value: 'annual' } ]),
              ]}
              onChange={handleSelectFrequency}
            />
          </Box>

          {(isPlanUpgrade && isCurrentSubAnnual) &&
            <>
              <Box mt={2}>
                <Typography variant="h6">Inicio de la suscripción</Typography>
              </Box>
              <Box className={classes.group} p={3}>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="subStart" name="subStart" value={subStart} onChange={event => setSubStart(event.target.value)}>
                    <FormControlLabel
                      value="now"
                      control={<Radio />}
                      label={
                        <Box display="flex" alignItems="baseline">
                          <Typography variant="body1">Iniciar ahora</Typography>
                          <Box ml={1}>
                            <Typography variant="body2">
                              - Con un descuento de <strong>${tds(discountToShow)}</strong>,
                              según los meses restantes de su plan actual
                            </Typography>
                          </Box>
                        </Box>
                      }
                      disabled={selectedFrequency === 'monthly'}
                    />
                    <FormControlLabel
                      value="after"
                      control={<Radio />}
                      label={
                        <Box display="flex" alignItems="baseline">
                          <Typography variant="body1">Iniciar después</Typography>
                          <Box ml={1}>
                            <Typography variant="body2">-  El <strong>{getDayIgnoreTZ(payDayStartingAfter)}</strong> cuando
                            termine la suscripción actual</Typography>
                          </Box>
                        </Box>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </>
          }

          <Box mt={2}>
            <Typography variant="h6">Información de facturación</Typography>
          </Box>
          <Box className={classes.group} p={3}>
            {hasBillingData ?
              <Box>
                <Typography color="textPrimary" variant="body1">
                  <strong>{billingData.legalName}</strong>
                </Typography>
                <Typography className={classes.fontWeight500} color="textPrimary" variant="body1">
                  {billingData.rut}
                </Typography>
                <Typography className={classes.fontWeight500} color="textPrimary" variant="body2">
                  <Tooltip title={economicActivityLabel}>
                    <span>
                      {twe(economicActivityLabel, 64)}
                    </span>
                  </Tooltip>
                </Typography>
                <br />
                <Typography color="textPrimary" variant="body2">
                  {billingData.commune}
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {billingData.address}
                </Typography>
                <br />
                <Typography color="textPrimary" variant="body2">
                  {billingData.contact}
                </Typography>
              </Box>
              : <Box display="flex" alignItems="center">
                <Typography variant="body2">No se han agregado datos de facturación</Typography>
                <Box flexGrow={1} />
                <Link className={classes.link} variant="body2" onClick={actions.goToSubscriptionUrl}>
                  Ir a agregar datos de facturación
                </Link>
              </Box>
            }
          </Box>
          {hasBadData && !hasBillingData && <FormHelperText error>Debes agregar la información de facturación</FormHelperText>}
          <Box mt={2}>
            <Typography variant="h6">Medio de pago</Typography>
          </Box>
          <Box className={classes.group} p={3}>
            {hasCreditCard ?
              <Box display="flex" alignItems="center">
                <Payment />
                <Box ml={1}>
                  <Typography variant="body2">
                    {creditCard.type} terminada en {creditCard.lastDigits}
                  </Typography>
                </Box>
              </Box>
              : <Box display="flex" alignItems="center">
                <Typography variant="body2">No se ha asociado un medio de pago</Typography>
                <Box flexGrow={1} />
                <Link className={classes.link} variant="body2" onClick={actions.goToSubscriptionUrl}>
                  Ir a agregar medio de pago
                </Link>
              </Box>
            }
          </Box>
          {hasBadData && !hasCreditCard && <FormHelperText error>Debes agregar un medio de pago</FormHelperText>}
          <br />
          {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
        </Box>
      </DialogContent>
      <Box className={clsx(classes.totalPay, classes.backgroundEnphasis)} display="flex" alignItems="center"
        justifyContent="space-between" pt={3} px={8}>
        <Typography variant="h5">Monto a pagar el siguiente día {getDayIgnoreTZ(payDay)}</Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="h4">
            {tds(totalAmount)} {selectedPlan.unitValueId}
          </Typography>
          <Box mx={1} />
          <Typography variant="body2">
            IVA incluido
          </Typography>
        </Box>
      </Box>
      <DialogActions className={classes.backgroundEnphasis}>
        <Button variant="outlined" className={classes.cancelBtn} onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton variant="contained" fetchStatus={fetchStatus} onClick={handleConfirm} color="secondary">
          ¡Sí, confirmar!
        </DialogButton>
      </DialogActions>
    </>
  );
};

SubscriptionCreateOrUpdateDialog.propTypes = {
  actions: PropTypes.shape({
    subscribePlan: PropTypes.func.isRequired,
    goToSubscriptionUrl: PropTypes.func.isRequired,
    closeDialog: PropTypes.func.isRequired,
  }).isRequired,
  selectedPlan: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    amount: PropTypes.string.isRequired,
    unitValueId: PropTypes.string.isRequired,
    extraUserAmount: PropTypes.string,
    extraGbStorageAmount: PropTypes.string,
    chargeDay: PropTypes.number.isRequired,
  }).isRequired,
  creditCard: PropTypes.shape({
    lastDigits: PropTypes.string,
    type: PropTypes.string,
  }),
  billingData: PropTypes.shape({
    id: PropTypes.string,
    legalName: PropTypes.string,
    rut: PropTypes.string,
    economicActivityCode: PropTypes.string,
    economicActivityName: PropTypes.string,
    commune: PropTypes.string,
    address: PropTypes.string,
    contact: PropTypes.string,
  }),
  isSubCreation: PropTypes.bool,
  currentSubscription: PropTypes.shape({
    planId: PropTypes.string.isRequired,
    billingFrequencyId: PropTypes.string,
    endDate: PropTypes.string,
  }),
  isYearlySelected: PropTypes.bool,
};


export {
  SubscriptionCreateOrUpdateDialog,
};
