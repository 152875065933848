import React from 'react';
import { Box, Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { LabelChip } from 'src/components';
import { getDayIgnoreTZ } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    background: 'none',
    border: `1px solid ${theme.palette.common.gray400}`,
  },
  title: {
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  description: {
    color: theme.palette.common.gray600,
  },
}));

const NextSubscriptionSection = ({ subscription }) => {
  const classes = useStyles();

  const { billingFrequencyName, startDate, planTypeId, planName, planDescription } = subscription;

  const isCustomPlan = planTypeId === 'custom';
  const name = isCustomPlan ? 'Personalizado' : planName;
  const description = isCustomPlan ? 'Para empresas con un plan a su medida' : planDescription;

  return (
    <Box py={4}>
      <Box display="flex" alignItems="flex-end" pb={0.5}>
        <Box flexGrow={1}>
          <Typography variant="h4" color="primary">Siguiente suscripción</Typography>
        </Box>
      </Box>
      <Paper elevation={0} className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item>
            <Paper>
              <Box display="flex" p={4} flexDirection="column">
                <Typography color="textPrimary" variant="h6" className={classes.title}>
                  Plan {name}
                </Typography>
                <Typography color="textSecondary" variant="body2" className={classes.description}>
                  {description}
                </Typography>
                <Box mt={1} display="flex" alignContent="center">
                  <Box mr={1}>
                    <LabelChip color="default" variant="outlined" label={`Empieza el ${getDayIgnoreTZ(startDate)}`} />
                  </Box>
                  <Box>
                    <LabelChip color="default" variant="outlined" label={`Modalidad ${billingFrequencyName}`} />
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

NextSubscriptionSection.propTypes = {
  subscription: PropTypes.shape({
    billingFrequencyName: PropTypes.string,
    startDate: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    planTypeId: PropTypes.oneOf([ 'person', 'custom', 'company', 'company-trial' ]),
    planName: PropTypes.string,
    planDescription: PropTypes.string,
  }),
};


export { NextSubscriptionSection };