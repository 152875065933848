import React, { useContext, useEffect, useState } from 'react';
import { Container, Button, TextField, Box, Paper, Grid, Typography, InputLabel, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { usersApi } from 'src/services';
import { WebContext } from 'src/scenes/web-context';
import { TitleContainer, ContentContainer, EmailVerificationAlert, AlertWrapper } from 'src/components';


const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    background: 'none',
    border: `1px solid ${theme.palette.common.gray400}`,
    marginBottom: theme.spacing(2),
  },
  paperInternal: {
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  button: {
    height: theme.spacing(5),
  },
}));

const ProfileContainer = () => {

  const classes = useStyles();

  const { currentUser: { firstName, lastName, email, emailVerified } } = useContext(WebContext);

  const [ userInfo, setUserInfo ] = useState({ password: '', repassword: '' });

  const [ submitted, setSubmitted ] = useState(false);
  const [ ok, setOk ] = useState(false);

  useEffect(() => {
    document.title = 'Perfil - Nviro Capture';
  }, []);

  const handleSubmit = async () => {
    const { password, repassword } = userInfo;
    setSubmitted(true);
    if (password === repassword && password.length >= 8) {
      await usersApi.updateUser({ password });
      setOk(true);
    }
  };

  const handleChange = e => setUserInfo(pui => ({ ...pui, [e.target.name]: e.target.value }));

  const { password, repassword } = userInfo;

  const commonProps = { fullWidth: true, size: 'small', variant: 'outlined' };

  return (
    <>
      <Grid container>
        <TitleContainer>Perfil de usuario</TitleContainer>
        <ContentContainer>
          <Container maxWidth="lg">
            <Box py={3} px={5}>
              <EmailVerificationAlert mt={1} mb={2} px={2}/>
              <Typography variant="h4" color="primary">Datos</Typography>
              <Paper className={classes.paper} elevation={0}>
                <Paper className={classes.paperInternal}>
                  <Grid container>
                    <Grid item md={4} sm={6} xs={12} >
                      <Box mx={4}>
                        <InputLabel shrink>Nombre</InputLabel>
                        <TextField disabled defaultValue={firstName} {...commonProps} />
                      </Box>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Box mx={4}>
                        <InputLabel shrink>Apellido</InputLabel>
                        <TextField disabled defaultValue={lastName} {...commonProps} />
                      </Box>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Box mx={4}>
                        <InputLabel shrink>Email</InputLabel>
                        <TextField disabled defaultValue={email} {...commonProps} />
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Paper>

              {emailVerified &&
                <>
                  <Typography variant="h4" color="primary">Cambiar contraseña</Typography>
                  <Paper className={classes.paper} elevation={0}>
                    <Paper className={classes.paperInternal}>
                      <Grid container>
                        <Grid item md={4} sm={6} xs={12}>
                          <Box mx={4}>
                            <InputLabel shrink>Nueva contraseña</InputLabel>
                            <TextField type="password" name="password" onChange={handleChange} value={password} {...commonProps} />
                            {submitted && !password && <FormHelperText error>Debes ingresar una contraseña</FormHelperText>}
                            {submitted && password.length < 8 && <FormHelperText error>
                              La contraseña debe tener más de 8 caracteres
                            </FormHelperText>}
                          </Box>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                          <Box mx={4}>
                            <InputLabel shrink>Confirmar contraseña</InputLabel>
                            <TextField type="password" name="repassword" onChange={handleChange} value={repassword} {...commonProps} />
                            {submitted && password !== repassword && <FormHelperText error>Las contraseñas deben coincidir</FormHelperText>}
                          </Box>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12} alignContent="flex-end">
                          <Box mx={4}>
                            <Button className={classes.button} color="primary" variant="contained" onClick={handleSubmit}>
                              Cambiar contraseña
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                    {ok &&
                      <Box mt={2}>
                        <AlertWrapper severity="success" variant="filled">
                          Cambio de contraseña exitoso
                        </AlertWrapper>
                      </Box>
                    }
                  </Paper>
                </>
              }
            </Box>
          </Container>
        </ContentContainer>
      </Grid>
    </>);
};

export {
  ProfileContainer,
};
