import React, { Fragment, useEffect, useState } from 'react';
import { Box, Divider, List, ListSubheader, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CreditCard, Link } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';
import urlJoin from 'url-join';

import { DrawerWrapper } from 'src/components';


const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: 'transparent !important',
    border: `1px solid ${theme.palette.common.gray}`,
    height: '32px',
    width: '32px',
    marginRight: theme.spacing(1),
  },
  listItemSelected: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: theme.palette.common.white,
  },
  listItem: {
    borderRadius: '6px',
  },
  list: {
    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  listItemIcon: {
    minWidth: '36px',
  },
  sectionHeader: {
    lineHeight: 0,
  },
  chip: {
    fontWeight: 'bold',
    width: '100%',
  },
  chipLabel: {
    flexGrow: 1,
  },
  workIn: {
    lineHeight: '1.2',
  },
}));

const itemList = [
  {
    Icon: CreditCard,
    label: 'Perfil',
    endPath: 'profile',
    sectionName: 'General',
  },
  {
    Icon: Link,
    label: 'Eliminar cuenta',
    endPath: 'data-remove',
  },
  // TODO: Cuando se implemente la suscripción agregamos esto
  // {
  //   Icon: DataUsage,
  //   label: 'Suscripción',
  //   endPath: 'subscription',
  //   sectionName: 'Plan y facturación',
  //   subPaths: [ 'comparison' ],
  // },
  // {
  //   Icon: CashMultiple,
  //   label: 'Historial de pagos',
  //   endPath: 'payment-history',
  //   isActiveSubRequired: false,
  //   isRequiredToBeAnEmployeeOfCswOrNviro: false,
  // },
];

const getIndex = (currentPathname, filteredItemList) => filteredItemList.findIndex(item => {
  const lastString = currentPathname.split('/').pop();
  return item.endPath === lastString || item.subPaths?.includes(lastString);
});

const UserSettingsDrawer = () => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  const filteredItemList = itemList.filter(item => !item.isRequiredToBeAnEmployeeOfCswOrNviro);

  const [ selectedIndex, setSelectedIndex ] = useState(getIndex(pathname, filteredItemList));

  const baseUrl = `/web/user`;

  const handleSelectedIndex = index => () => {
    history.push(urlJoin(baseUrl, itemList[index].endPath));
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedIndex(getIndex(pathname, filteredItemList));
  }, [ pathname, filteredItemList ]);

  const commonListItemProps = { button: true, component: 'a', classes: { root: classes.listItem, selected: classes.listItemSelected } };

  return (
    <DrawerWrapper>
      <Box mt={12}>
        <List className={classes.list}>
          {filteredItemList.map(({ Icon, label, sectionName }, itemIndex) => (
            <Fragment key={itemIndex}>
              {sectionName &&
                <>
                  <Box {...(itemIndex === 0 ? { pb: 2 } : { py: 2 })} mx={-1}>
                    <Divider />
                  </Box>
                  <Box py={2}>
                    <ListSubheader className={classes.sectionHeader} color="primary">
                      {sectionName}
                    </ListSubheader>
                  </Box>
                </>
              }
              <ListItem
                selected={selectedIndex === itemIndex}
                onClick={handleSelectedIndex(itemIndex)} {...commonListItemProps}
              >
                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            </Fragment>
          ))}
        </List>
      </Box>
    </DrawerWrapper>
  );
};


export { UserSettingsDrawer };